import React, {
  FC,
  PropsWithChildren,
  useState,
  createContext,
  useContext,
} from "react";
import { IAppUser, ICity, INotification } from "@skuare/common";
import { GetUserDetailsWithSignup, UpdateMyDetails } from "../api";
import { User } from "firebase/auth";

// TODO Change to Firebase User

interface IUserContext {
  authCheckDone: boolean;
  userDetailsFetched: boolean;
  user: User | null;
  userCity: ICity | null;
  userDetails: IAppUser | null;
  notifications: INotification[];
  setUserInfo: (user: User | null) => void;
  setUserCity: (cityId: ICity, update?: boolean) => void;
  updateUserDetails: (userDetails: Partial<IAppUser>) => Promise<IAppUser>;
  updateUserNotifications: (notifications: INotification[]) => void;
  cleanUserInfo: () => void;
}

const defaultContext: IUserContext = {
  authCheckDone: false,
  userDetailsFetched: false,
  user: null,
  userCity: null,
  userDetails: null,
  notifications: [],
  setUserInfo: () => {},
  setUserCity: () => {},
  updateUserDetails: () => Promise.reject(false),
  updateUserNotifications: () => {},
  cleanUserInfo: () => {},
};

const UserContext = createContext(defaultContext);

export const useUserContext = () => useContext(UserContext);

export const useUserContextProvider = (): IUserContext => {
  const [authCheckDone, setAuthCheckDone] = useState<boolean>(false);
  const [userDetailsFetched, setUserDetailsFetched] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [userCity, setUserCity] = useState<ICity | null>(null);
  const [userDetails, setUserDetails] = useState<IAppUser | null>(null);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const setUsersCity = (city: ICity, update?: boolean) => {
    setUserCity(city);
    if (update) {
      updateUserDetails({ city: city.id }).catch((err) => {
        console.error("Error Occured in Updating City of User", err);
      });
    }
    // updateUserDetails({city: city.id})
    //   .then(_updatedUserDetails => {
    //     setUserCity(city);
    //   })
    //   .catch(err => {
    //     console.error("Error Occured in Updating City of User", err);
    //     // We are still setting city to make sure we can proceed with User Flow
    //     setUserCity(city);
    //   });
  };

  const updateUserDetails = (userDetailsUpdate: Partial<IAppUser>) =>
    userDetailsFetched
      ? UpdateMyDetails(userDetailsUpdate).then((Resp) => {
          setUserDetails(Resp);
          return Resp;
        })
      : Promise.reject(new Error("User Details Not Yet Fetched"));

  const setUserInfo = (userData: User | null) => {
    setUser(userData);
    setAuthCheckDone(true);
    if (userData) {
      GetUserDetailsWithSignup(userData.uid).then((resp) => {
        setUserDetails(resp);
        setUserDetailsFetched(true);
      });
    }
  };

  const cleanUserInfo = () => {
    setUser(null);
    setUserDetails(null);
    setUserCity(null);
  };

  return {
    authCheckDone,
    userDetailsFetched,
    user,
    userCity,
    userDetails,
    notifications,
    setUserInfo,
    setUserCity: setUsersCity,
    updateUserDetails,
    updateUserNotifications: setNotifications,
    cleanUserInfo,
  };
};

export const UserContextProvider: FC<PropsWithChildren> = (props) => {
  const providerValue = useUserContextProvider();
  return (
    <UserContext.Provider value={providerValue}>
      {props.children}
    </UserContext.Provider>
  );
};
