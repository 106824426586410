import { GetAreas } from "../../api";
import { IArea } from "@skuare/common";
import { GridColDef } from "@mui/x-data-grid";
import { DataContainer } from "../common";
import { PATHS } from "../../Constants";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "isActive",
    headerName: "Active",
    type: "boolean",
    flex: 1,
    editable: true,
  },
  {
    field: "pinCode",
    headerName: "Pin Code",
    flex: 1,
  },
  {
    field: "city",
    headerName: "City",
    flex: 1,
    valueFormatter: (params) => `${params.value.name}`,
  },
  {
    field: "createdAt",
    headerName: "Created",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
];

export const AreaList = () => (
  <DataContainer<IArea>
    header="Area"
    GetData={GetAreas}
    columns={columns}
    path={PATHS.AREA_LIST}
    addNew={PATHS.AREA_ADD}
  />
);
