import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FailureResponse,
  ImageUploadField,
  Loading,
  SocialLinksField,
  SuccessResponse,
} from "../common";
import { APIResponse, GetArtist, UpdateArtist } from "../../api";
import { getCurrentUser } from "../../services";
import { PATHS } from "../../Constants";
import { ArtistType, IArtist, ISocialLink } from "@skuare/common";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  stageName: Yup.string().required("Required"),
  imageUrl: Yup.string().required("Required"),
});

export const EditArtist = () => {
  const { id = "" } = useParams();
  const [initialValues, setInitialValues] = useState<Partial<IArtist>>({
    firstName: "",
    lastName: "",
    stageName: "",
    imageUrl: "",
    description: "",
    isVerified: true,
    isActive: true,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [apiResponse, setAPIResponse] = useState<APIResponse>({
    status: null,
    data: null,
    error: null,
  });

  useEffect(() => {
    const getData = async () => {
      const artist = await GetArtist(id, getCurrentUser());
      setIsLoading(false);
      setInitialValues(artist);
    };

    getData();
  }, [id]);

  return apiResponse.status === "success" ? (
    <SuccessResponse
      message="Artist Edited Successfully"
      id={apiResponse.data.id}
      viewAllPath={PATHS.ARTIST_LIST}
      shareUrl={apiResponse.data.shareUrl}
    />
  ) : isLoading ? (
    <Loading isLoading={isLoading} />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Edit Artist
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = await UpdateArtist(
                id,
                values,
                await getCurrentUser()
              );
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "success",
                data,
              }));
            } catch (error) {
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "failure",
                error,
              }));
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <Field
                        component={ImageUploadField}
                        id="artist-add-imageUrl"
                        name="imageUrl"
                        label="Artist Image"
                        value={values.imageUrl}
                        onChange={async (value: string) => {
                          setFieldTouched("imageUrl");
                          await setFieldValue("imageUrl", value);
                        }}
                        error={touched.imageUrl && !!errors.imageUrl}
                      />
                    </div>
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="artist-add-firstName"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            label="First Name"
                            error={touched.firstName && !!errors.firstName}
                            helperText={touched.firstName && errors.firstName}
                            required={true}
                            onFocus={() => setFieldTouched("firstName")}
                          />
                        </div>
                      </Grid>

                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="artist-add-lastName"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            label="Last Name"
                            error={touched.lastName && !!errors.lastName}
                            helperText={touched.lastName && errors.lastName}
                            required={true}
                            onFocus={() => setFieldTouched("lastName")}
                          />
                        </div>
                      </Grid>
                    </Stack>

                    <div>
                      <TextField
                        id="artist-add-stageName"
                        name="stageName"
                        value={values.stageName}
                        onChange={handleChange}
                        label="Stage Name"
                        error={touched.stageName && !!errors.stageName}
                        helperText={touched.stageName && errors.stageName}
                        required={true}
                        onFocus={() => setFieldTouched("stageName")}
                      />
                    </div>

                    <div>
                      <TextField
                        multiline
                        rows={3}
                        id="artist-add-description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        label="Description"
                        required={true}
                        error={touched.description && !!errors.description}
                        helperText={touched.description && errors.description}
                        onFocus={() => setFieldTouched("description")}
                      />
                    </div>

                    <div>
                      <TextField
                        select
                        id="artist-add-artist-type"
                        value={values.artistType}
                        label="Artist Type"
                        name="artistType"
                        onChange={handleChange}
                        required={true}
                      >
                        <MenuItem value={ArtistType.DiscJockey}>
                          {ArtistType.DiscJockey}
                        </MenuItem>
                        <MenuItem value={ArtistType.StandUpComedian}>
                          {ArtistType.StandUpComedian}
                        </MenuItem>
                        <MenuItem value={ArtistType.Band}>
                          {ArtistType.Band}
                        </MenuItem>
                        <MenuItem value={ArtistType.Singer}>
                          {ArtistType.Singer}
                        </MenuItem>
                      </TextField>
                    </div>

                    <div>
                      <SocialLinksField
                        id="artist-add-social-links"
                        value={values.socialLinks || []}
                        onChange={(event: any, value: ISocialLink[]) =>
                          setFieldValue("socialLinks", value)
                        }
                      />
                    </div>

                    <Stack direction="row" justifyContent="space-between">
                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="artist-add-isVerified"
                                name="isVerified"
                                checked={values.isVerified}
                                value={values.isVerified}
                                onChange={handleChange}
                              />
                            }
                            label="Verified"
                            labelPlacement="start"
                            name="isVerified"
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="artist-add-isActive"
                                name="isActive"
                                checked={values.isActive}
                                value={values.isActive}
                                onChange={handleChange}
                              />
                            }
                            label="Active"
                            labelPlacement="start"
                            name="isActive"
                          />
                        </FormControl>
                      </div>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loading isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Box>
      {apiResponse.status === "failure" ? (
        <FailureResponse error={apiResponse.error} />
      ) : null}
    </>
  );
};
