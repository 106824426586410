import React, { useEffect, useState } from "react";
import {
  EventByType,
  IAggregatedEvent,
  IArea,
  IArtist,
  ICategory,
  ICity,
  ICuisine,
  IEstablishment,
  IEventManager,
  IEventSpace,
  IOrganizer,
} from "@skuare/common";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import { EditEventStep1 } from "./EditEventStep1";
import { EditEventStep2 } from "./EditEventStep2";
import { EditEventStep3 } from "./EditEventStep3";
import { EditEventStep4 } from "./EditEventStep4";
import { EditEventStep5 } from "./EditEventStep5";
import { EditEventStep6 } from "./EditEventStep6";
import { getCurrentUser } from "../../services";
import { GetEvent, UpdateEvent } from "../../api";
import { FailureResponse, Loading, SuccessResponse } from "../common";
import { PATHS } from "../../Constants";

const steps = [
  "City",
  "Organizer",
  "Event Details",
  "Multi Media",
  "Tickets",
  "Preview",
];

export const EditEvent = () => {
  const { id = "" } = useParams();
  const [value, setValue] = useState<Partial<IAggregatedEvent>>({});
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [inValidStep, setInValidStep] = useState(new Set<number>([]));
  const [selectedCity, setSelectedCity] = useState<ICity>();
  const [selectedEstablishmentOption, setSelectedEstablishmentOption] =
    useState<IEstablishment>();
  const [selectedEventManagerOption, setSelectedEventManagerOption] =
    useState<IEventManager>();
  const [selectedEventSpaceOption, setSelectedEventSpaceOption] = useState<
    IEventSpace | string
  >();
  const [selectedArea, setSelectedArea] = useState("");

  const [selectedOrganizers, setSelectedOrganizers] = useState<IOrganizer[]>(
    []
  );
  const [selectedArtists, setSelectedArtists] = useState<IArtist[] | string[]>(
    []
  );
  const [selectedCategories, setSelectedCategories] = useState<
    ICategory[] | string[]
  >([]);
  const [selectedCuisines, setSelectedCuisines] = useState<
    ICuisine[] | string[]
  >([]);
  const [updatedEvent, setUpdatedEvent] = useState<Partial<IAggregatedEvent>>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await GetEvent(id, await getCurrentUser());
      setSelectedCity(data.city as ICity);
      setSelectedArea(
        `${(data.area as IArea).name}, ${(data.area as IArea).pinCode}`
      );
      let eventById: string;
      if (data.eventByType === EventByType.Establishment) {
        setSelectedEstablishmentOption(data.eventBy as IEstablishment);
        eventById = (data.eventBy as IEstablishment).id;
      } else {
        setSelectedEventManagerOption(data.eventBy as IEventManager);
        eventById = (data.eventBy as IEventManager).id;
      }
      setSelectedArtists(data.artists);
      setSelectedCategories(data.categories);
      setSelectedOrganizers(data.organizers);
      setSelectedEventSpaceOption(data.eventSpace);
      setSelectedCuisines(data.cuisines);
      setValue({
        ...data,
        city: (data.city as ICity).id,
        area: (data.area as IArea).id,
        eventBy: eventById,
        eventSpace: (data.eventSpace as IEventSpace)?.id,
        artists: data.artists.map((artist) => (artist as IArtist).id),
        cuisines: data.cuisines.map((cuisine) => (cuisine as ICuisine).id),
        categories: data.categories.map(
          (category) => (category as ICategory).id
        ),
      });

      setIsLoading(false);
    };

    getData();
  }, []);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const isStepInValid = (step: number) => {
    return inValidStep.has(step);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const data = await UpdateEvent(id, value, getCurrentUser());
      setUpdatedEvent(data);
      setIsLoading(false);
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      await handleSubmit();
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event: any) => {
    if (event.target.type === "checkbox") {
      setValue((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    } else if (event.target.value) {
      setValue((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleEventByTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    eventByType: EventByType
  ) => {
    setValue((prevState) => ({
      ...prevState,
      eventByType,
      hostAtEstablishment: eventByType === EventByType.Establishment,
    }));

    setInValidStep((prevState: Set<number>) => {
      return new Set([...prevState, 1]);
    });
  };

  const onChange = (event: any, value: any, name: string) =>
    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const getComponent = () => {
    if (activeStep === 0)
      return (
        <EditEventStep1
          value={value}
          handleChange={handleChange}
          handleEventByTypeChange={handleEventByTypeChange}
          onChange={onChange}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 1)
      return (
        <EditEventStep2
          value={value}
          handleChange={handleChange}
          onChange={onChange}
          selectedEstablishmentOption={selectedEstablishmentOption}
          setSelectedEstablishmentOption={setSelectedEstablishmentOption}
          selectedEventManagerOption={selectedEventManagerOption}
          setSelectedEventManagerOption={setSelectedEventManagerOption}
          selectedEventSpaceOption={selectedEventSpaceOption}
          setSelectedEventSpaceOption={setSelectedEventSpaceOption}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          selectedOrganizers={selectedOrganizers}
          setSelectedOrganizers={setSelectedOrganizers}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 2)
      return (
        <EditEventStep3
          value={value}
          handleChange={handleChange}
          onChange={onChange}
          selectedArtists={selectedArtists}
          setSelectedArtists={setSelectedArtists}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedCuisines={selectedCuisines}
          setSelectedCuisines={setSelectedCuisines}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 3)
      return (
        <EditEventStep4
          value={value}
          onChange={onChange}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 4)
      return (
        <EditEventStep5
          value={value}
          onChange={onChange}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 5)
      return (
        <EditEventStep6
          value={value}
          selectedCity={selectedCity}
          selectedEstablishmentOption={selectedEstablishmentOption}
          selectedEventManagerOption={selectedEventManagerOption}
          selectedEventSpaceOption={selectedEventSpaceOption}
          selectedArea={selectedArea}
          selectedOrganizers={selectedOrganizers}
          selectedArtists={selectedArtists}
          selectedCategories={selectedCategories}
          selectedCuisines={selectedCuisines}
        />
      );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <h1>Edit Event</h1>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean; disabled?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step key={label} {...stepProps}>
                <StepButton {...labelProps}>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
        {isLoading ? <Loading isLoading={isLoading} /> : getComponent()}
        {activeStep === steps.length ? (
          <React.Fragment>
            {error ? (
              <FailureResponse error={error} />
            ) : (
              <SuccessResponse
                id={updatedEvent.id}
                message="Event Edited Successfully"
                viewAllPath={PATHS.EVENT_LIST}
                shareUrl={updatedEvent?.shareUrl}
                viewExternalUrl={updatedEvent?.shareUrl}
              />
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                variant="contained"
                disabled={isStepInValid(activeStep)}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Loading isLoading={isLoading} />
    </>
  );
};
