import { IOffer } from "@skuare/common";
import { GridColDef } from "@mui/x-data-grid";
import { DataContainer, OrganizerChip } from "../common";
import { PATHS } from "../../Constants";
import { Avatar } from "@mui/material";
import { GetOffers } from "../../api";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "title",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "imageUrl",
    headerName: "Image",
    flex: 0.5,
    renderCell: (params) => (
      <Avatar alt={params.row.title} src={params.value} />
    ),
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 0.5,
    renderCell: (params) =>
      params.value === -1 ? <AllInclusiveIcon /> : params.value,
  },
  {
    field: "maxRedeem",
    headerName: "Max Redeem",
    flex: 0.5,
  },
  {
    field: "organizer",
    headerName: "Organizer",
    flex: 1.5,
    renderCell: (params) => (
      <OrganizerChip
        organizer={{ by: params.value, type: params.row.organizerType }}
      />
    ),
  },
  {
    field: "isActive",
    headerName: "Active",
    type: "boolean",
    flex: 0.5,
    editable: true,
  },
  {
    field: "startDateTime",
    headerName: "Start",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "endDateTime",
    headerName: "End",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "createdAt",
    headerName: "Created",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "valid",
    headerName: "Valid",
    type: "boolean",
    valueGetter: (params) => new Date() < new Date(params.row.endDateTime),
  },
];

export const OfferList = () => (
  <DataContainer<IOffer>
    header="Offer"
    GetData={GetOffers}
    columns={columns}
    path={PATHS.OFFER_LIST}
    columnVisibilityModel={{
      id: false,
      createdAt: false,
      updatedAt: false,
    }}
    addNew={PATHS.OFFER_ADD}
  />
);
