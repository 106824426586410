import "./styles.css";
import React, { FC, useRef, useState } from "react";
import { Box, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { IMultiMedia, MultiMediaType } from "@skuare/common";

interface FileUploadFieldProps {
  id: string;
  name: string;
  onChange: (value: any) => void;
  multi?: boolean;
  accepts?: string[];
  value: any;
  error?: boolean;
}

export const FileUploadField: FC<FileUploadFieldProps> = ({
  id,
  name,
  onChange,
  multi = false,
  accepts = ["image/*", "video/*"],
  value,
  error = false,
}) => {
  const [multimedia, setMultimedia] = useState<
    (IMultiMedia & {
      name: string;
      size: number;
      lastModified: Date;
    })[]
  >(value);

  const inputFile = useRef<HTMLInputElement | null>(null);
  const openFileExplorer = () => inputFile.current?.click();

  const fileToBase64 = (file: File) => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const fileList: File[] = Array.from(event.target.files);
    const data = await Promise.all(
      fileList.map(async (file: File) => ({
        url: (await fileToBase64(file)) as string,
        name: file.name,
        size: file.size,
        lastModified: new Date(file.lastModified),
        type: file.type.startsWith("image")
          ? MultiMediaType.Image
          : MultiMediaType.Video,
      }))
    );

    setMultimedia(data);
    onChange(data);
  };

  return (
    <>
      <input
        type="file"
        className="file-upload-field-input"
        ref={inputFile}
        onClick={openFileExplorer}
        onChange={handleFileChange}
        multiple={multi}
        accept={accepts.join(", ")}
      />

      {multimedia.length ? (
        <ImageList
          sx={{
            width: "100%",
            height: 500,
            border: "1px solid grey",
            borderRadius: "5px",
          }}
          onClick={openFileExplorer}
        >
          {multimedia.map((item) => (
            <ImageListItem
              key={item.name}
              sx={{
                width: "200px",
                maxHeight: "200px",
                border: error ? "1px solid red" : "1px solid grey",
              }}
            >
              {item.type === MultiMediaType.Image ? (
                <img
                  src={item.url}
                  alt={item.name}
                  loading="lazy"
                  style={{ width: "200px", maxHeight: "200px" }}
                />
              ) : (
                <video
                  src={item.url}
                  title={item.name}
                  style={{ width: "200px", maxHeight: "200px" }}
                />
              )}
              <ImageListItemBar
                title={item.name}
                subtitle={
                  <span>Size: {(item.size / 1000 / 1000).toFixed(2)} MB</span>
                }
                position="below"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: 500,
            border: error ? "1px solid red" : "1px solid grey",
            borderRadius: "5px",
          }}
          onClick={openFileExplorer}
        >
          <p>Click to Upload File</p>
        </Box>
      )}
    </>
  );
};
