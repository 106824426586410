import React, { FC, useState } from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { ITicketItem } from "@skuare/common";
import { arrayToObject } from "../../utils";
import { CurrencyRupee } from "@mui/icons-material";
import { Formik, Form } from "formik";

interface TicketFormErrors {
  ticket: {
    ticketName?: string;
    lastBookingTime?: string;
    validFrom?: string;
    validTo?: string;
  };
}

const entryForValues = ["Male", "Female", "Other", "Couple", "Family"];
interface TicketFormProps {
  value: ITicketItem;
  onChange: (value: ITicketItem) => void;
  setInValidStep: (value: (prevState: Set<number>) => Set<number>) => void;
}
export const TicketForm: FC<TicketFormProps> = ({
  value,
  onChange,
  setInValidStep,
}) => {
  const [entryFor, setEntryFor] = useState<string[]>(() => {
    let result = [];
    if (value.ticket.entryFor.male) result.push("Male");
    if (value.ticket.entryFor.female) result.push("Female");
    if (value.ticket.entryFor.other) result.push("Other");
    if (value.ticket.entryFor.couple) result.push("Couple");
    if (value.ticket.entryFor.family) result.push("Family");
    return result;
  });

  return (
    <Formik
      initialValues={value}
      onSubmit={() => {}}
      validate={(rawValues) => {
        let errors: Partial<TicketFormErrors> = {};

        if (!rawValues.ticket.ticketName) {
          errors = {
            ...errors,
            ticket: {
              ...errors.ticket,
              ticketName: "Required",
            },
          };
        }

        if (!rawValues.ticket.lastBookingTime) {
          errors = {
            ...errors,
            ticket: {
              ...errors.ticket,
              lastBookingTime: "Required",
            },
          };
        }

        if (!rawValues.ticket.validFrom) {
          errors = {
            ...errors,
            ticket: {
              ...errors.ticket,
              validFrom: "Required",
            },
          };
        }

        if (!rawValues.ticket.validTo) {
          errors = {
            ...errors,
            ticket: {
              ...errors.ticket,
              validTo: "Required",
            },
          };
        }

        if (Object.keys(errors).length > 0) {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState, 4]);
          });
        } else {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState].filter((v) => v !== 4));
          });
        }

        return errors;
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item md={6}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <div>
                  <TextField
                    name="ticketName"
                    label="Ticket Name"
                    value={value.ticket.ticketName}
                    onChange={async (event) => {
                      setFieldTouched("ticket.ticketName");
                      onChange({
                        ...value,
                        ticket: {
                          ...value.ticket,
                          ticketName: event.target.value,
                        },
                      });
                      await setFieldValue(
                        "ticket.ticketName",
                        event.target.value
                      );
                    }}
                    required={true}
                    error={
                      touched.ticket?.ticketName && !!errors.ticket?.ticketName
                    }
                  />
                </div>

                <div>
                  <TextField
                    name="ticketDescription"
                    label="Ticket Description"
                    value={value.ticket.ticketDescription}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        ticket: {
                          ...value.ticket,
                          ticketDescription: event.target.value,
                        },
                      })
                    }
                  />
                </div>

                <div>
                  <TextField
                    name="price"
                    label="Ticket Price"
                    type="number"
                    value={value.ticket.price}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        ticket: {
                          ...value.ticket,
                          price: Math.max(0, parseInt(event.target.value)),
                        },
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupee />
                        </InputAdornment>
                      ),
                    }}
                    required={true}
                  />
                </div>

                <div>
                  <TextField
                    name="quantity"
                    label="Total Quantity"
                    type="number"
                    value={value.totalQuantity}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        totalQuantity: Math.max(
                          0,
                          parseInt(event.target.value)
                        ),
                      })
                    }
                    required={true}
                  />
                </div>

                <div>
                  <TextField
                    name="bufferQuantity"
                    label="Buffer Quantity"
                    type="number"
                    defaultValue="10"
                    value={value.bufferQuantity}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        bufferQuantity: Math.max(
                          0,
                          parseInt(event.target.value)
                        ),
                      })
                    }
                  />
                </div>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Last Booking"
                      value={value.ticket.lastBookingTime}
                      onChange={(lastBookingTime) =>
                        onChange({
                          ...value,
                          ticket: {
                            ...value.ticket,
                            lastBookingTime:
                              lastBookingTime || value.ticket.lastBookingTime,
                          },
                        })
                      }
                    />
                  </LocalizationProvider>
                  <FormControl>
                    <FormHelperText>
                      Last Booking time for the Ticket
                    </FormHelperText>
                  </FormControl>
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Valid From"
                      value={value.ticket.validFrom}
                      onChange={(validFrom) =>
                        onChange({
                          ...value,
                          ticket: {
                            ...value.ticket,
                            validFrom: validFrom || value.ticket.validFrom,
                          },
                        })
                      }
                    />
                  </LocalizationProvider>
                  <FormControl>
                    <FormHelperText>Ticket validity starts at</FormHelperText>
                  </FormControl>
                </div>

                <div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Valid To"
                      value={value.ticket.validTo}
                      onChange={(validTo) =>
                        onChange({
                          ...value,
                          ticket: {
                            ...value.ticket,
                            validTo: validTo || value.ticket.validTo,
                          },
                        })
                      }
                    />
                  </LocalizationProvider>
                  <FormControl>
                    <FormHelperText>Ticket validity ends at</FormHelperText>
                  </FormControl>
                </div>

                <div>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="entry-for-input-label">
                      Entry For
                    </InputLabel>
                    <Select
                      labelId="entry-for-input-label"
                      name="entryFor"
                      multiple
                      value={entryFor}
                      onChange={(event: any) => {
                        const values = event.target.value;
                        setEntryFor(values);
                        onChange({
                          ...value,
                          ticket: {
                            ...value.ticket,
                            entryFor: {
                              ...value.ticket.entryFor,
                              ...arrayToObject(
                                entryForValues.map((value) =>
                                  value.toLowerCase()
                                ),
                                values.map((value: string) =>
                                  value.toLowerCase()
                                )
                              ),
                            },
                          },
                        });
                      }}
                      input={
                        <OutlinedInput id="entry-for-input" label="Entry For" />
                      }
                      renderValue={(selected: any[]) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: string) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {entryForValues.map((entryForValue: string) => (
                        <MenuItem key={entryForValue} value={entryForValue}>
                          <Checkbox
                            checked={entryFor.indexOf(entryForValue) !== -1}
                          />
                          <ListItemText primary={entryForValue} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
