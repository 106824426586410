import {BuildUrl} from "@skuare/common";
import {BASE_URL} from "../Constants";

export const GetApiUrl = (
    path: string,
    urlParams: { [key: string]: string | number} = {},
    queryParams: { [key: string]: string | number} = {}
) => BuildUrl(path, urlParams, queryParams, BASE_URL);

export const GetPathUrl = (
    path: string,
    urlParams: { [key: string]: string | number} = {},
    queryParams: { [key: string]: string | number} = {}
) => BuildUrl(path, urlParams, queryParams);
