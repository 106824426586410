import {GetGateKeepers} from "../../api";
import {IGateKeeper} from "@skuare/common";
import {GridColDef} from "@mui/x-data-grid";
import {DataContainer} from "../common";
import {PATHS} from "../../Constants";

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        flex: 1
    }, {
        field: "fullName",
        headerName: "Name",
        flex: 1
    }, {
        field: "email",
        headerName: "Email",
        flex: 1
    }, {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: 1
    }, {
        field: "shortCode",
        headerName: "Short Code",
        flex: 1
    }, {
        field: "isActive",
        headerName: "Active",
        type: "boolean",
        flex: 1,
        editable: true
    }, {
        field: "createdAt",
        headerName: "Created",
        type: "dateTime",
        flex: 1,
        valueFormatter: params => new Date(params.value).toLocaleString()
    }, {
        field: "updatedAt",
        headerName: "Updated",
        type: "dateTime",
        flex: 1,
        valueFormatter: params => new Date(params.value).toLocaleString()
    }
];

export const GateKeeperList = () => <DataContainer<IGateKeeper>
    header="Gate Keeper"
    GetData={GetGateKeepers}
    columns={columns}
    path={PATHS.GATE_KEEPER_LIST}
/>