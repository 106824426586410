import { FC } from "react";
import { Avatar, Chip } from "@mui/material";
import { ISocialLink } from "@skuare/common";

export interface SocialLinkChipProps {
  socialLink: ISocialLink;
}
export const SocialLinkChip: FC<SocialLinkChipProps> = ({
  socialLink,
  ...props
}) => (
  <Chip
    avatar={
      <Avatar alt={socialLink.platform} src={socialLink.platformIconUrl} />
    }
    label={socialLink.platform}
    variant="outlined"
    {...props}
  />
);
