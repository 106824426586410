import { IOffer, IPaginatedResponse } from "@skuare/common";
import axios from "axios";
import { URLS } from "../Constants";
import { GetApiUrl } from "../utils";
import { User } from "firebase/auth";

export const GetOffers = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<IOffer>>(
      GetApiUrl(URLS.OFFER_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((response) => response.data);

export const CreateOffer = async (
  offer: Partial<IOffer>,
  user: User | null
) => {
  try {
    const { data } = await axios.post<Partial<IOffer>>(
      GetApiUrl(URLS.OFFER_ADD),
      offer,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
