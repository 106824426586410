import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  EventByType,
  EventType,
  EventTypeDisplay,
  ICity,
} from "@skuare/common";
import { Formik, Form, Field } from "formik";
import { GetCities } from "../../api";
import { getCurrentUser } from "../../services";

interface AddEventStep1Errors {
  city: string;
  type: string;
  eventByType: string;
  hostAtEstablishment: string;
}

interface AddEventStep1Props {
  value: any;
  handleChange: (e: any) => void;
  handleEventByTypeChange: (
    event: React.MouseEvent<HTMLElement>,
    eventByType: EventByType
  ) => void;
  onChange: (e: React.SyntheticEvent, value: any, name: string) => void;
  selectedCity: any;
  setSelectedCity: (value: any) => void;
  setInValidStep: (value: (prevState: Set<number>) => Set<number>) => void;
}

export const AddEventStep1: FC<AddEventStep1Props> = ({
  value,
  handleChange,
  handleEventByTypeChange,
  onChange,
  selectedCity,
  setSelectedCity,
  setInValidStep,
}) => {
  const [cities, setCities] = useState<ICity[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const data = await GetCities(undefined, getCurrentUser());
      setCities(data.results);
      setIsLoading(false);
    };

    getData();
  }, []);

  return (
    <Formik
      initialValues={value}
      onSubmit={() => {}}
      validate={(values) => {
        const errors: Partial<AddEventStep1Errors> = {};
        if (!values.city) {
          errors.city = "Required";
        }

        if (!values.type) {
          errors.type = "Required";
        }

        if (Object.keys(errors).length > 0) {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState, 0]);
          });
        } else {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState].filter((v) => v !== 0));
          });
        }

        return errors;
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Grid container justifyContent="center">
            <Grid item md={4}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <h2>Select City</h2>

                <div>
                  <Field
                    component={Autocomplete}
                    loading={isLoading}
                    style={{ width: "100%" }}
                    disablePortal
                    id="event-add-city"
                    options={cities}
                    value={selectedCity}
                    onChange={async (event: any, option: ICity) => {
                      setFieldTouched("city");
                      if (option) {
                        setSelectedCity(option);
                        onChange(event, option.id, "city");
                        await setFieldValue("city", option.id);
                      } else {
                        setSelectedCity(undefined);
                        onChange(event, "", "city");
                        await setFieldValue("city", "");
                      }
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        name="city"
                        label="City"
                        error={touched["city"] && !!errors["city"]}
                        onTouchEnd={() => setFieldTouched("city")}
                        required={true}
                      />
                    )}
                    isOptionEqualToValue={(option: ICity, value: ICity) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option: ICity) => option.name}
                    getOptionDisabled={(option: ICity) => !option.isActive}
                  />
                </div>

                <div>
                  <TextField
                    select
                    id="event-add-event-type"
                    value={value.type}
                    label="Type"
                    name="type"
                    onChange={async (event) => {
                      handleChange(event);
                      await setFieldValue("type", event.target.value);
                    }}
                    required={true}
                  >
                    <MenuItem value={EventType.BrunchEvent}>
                      {EventTypeDisplay.BrunchEvent}
                    </MenuItem>
                    <MenuItem value={EventType.ChristmasEvent}>
                      {EventTypeDisplay.ChristmasEvent}
                    </MenuItem>
                    <MenuItem value={EventType.ExhibitionEvent}>
                      {EventTypeDisplay.ExhibitionEvent}
                    </MenuItem>
                    <MenuItem value={EventType.HalloweenEvent}>
                      {EventTypeDisplay.HalloweenEvent}
                    </MenuItem>
                    <MenuItem value={EventType.HoliEvent}>
                      {EventTypeDisplay.HoliEvent}
                    </MenuItem>
                    <MenuItem value={EventType.LiveBandEvent}>
                      {EventTypeDisplay.LiveBandEvent}
                    </MenuItem>
                    <MenuItem value={EventType.LiveScreeningEvent}>
                      {EventTypeDisplay.LiveScreeningEvent}
                    </MenuItem>
                    <MenuItem value={EventType.LoungeEvent}>
                      {EventTypeDisplay.LoungeEvent}
                    </MenuItem>
                    <MenuItem value={EventType.NavratriEvent}>
                      {EventTypeDisplay.NavratriEvent}
                    </MenuItem>
                    <MenuItem value={EventType.NewYearEvent}>
                      {EventTypeDisplay.NewYearEvent}
                    </MenuItem>
                    <MenuItem value={EventType.OneTimeEvent}>
                      {EventTypeDisplay.OneTimeEvent}
                    </MenuItem>
                    <MenuItem value={EventType.SportsEvent}>
                      {EventTypeDisplay.SportsEvent}
                    </MenuItem>
                    <MenuItem value={EventType.StandUpEvent}>
                      {EventTypeDisplay.StandUpEvent}
                    </MenuItem>
                  </TextField>
                </div>

                <div>
                  <FormControl sx={{ ml: -1 }}>
                    <FormControlLabel
                      label=""
                      labelPlacement="start"
                      control={
                        <ToggleButtonGroup
                          color="primary"
                          value={value.eventByType}
                          exclusive
                          aria-label="Event By"
                          onChange={handleEventByTypeChange}
                        >
                          <ToggleButton value={EventByType.Establishment}>
                            Establishment
                          </ToggleButton>
                          <ToggleButton value={EventByType.EventManager}>
                            Event Manager
                          </ToggleButton>
                        </ToggleButtonGroup>
                      }
                    />
                  </FormControl>
                </div>

                <div>
                  <FormControl sx={{ ml: -1 }}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={value.hostAtEstablishment}
                          onChange={handleChange}
                          disabled={
                            value.eventByType === EventByType.EventManager
                          }
                        />
                      }
                      label="Host at Establishment"
                      labelPlacement="start"
                      name="hostAtEstablishment"
                    />
                  </FormControl>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
