import { Chip } from "@mui/material";
import { FC, ReactElement } from "react";
import { OrderStatus } from "@skuare/common";
import ErrorIcon from "@mui/icons-material/Error";
import PaidIcon from "@mui/icons-material/Paid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import CancelIcon from "@mui/icons-material/Cancel";
import { OrderStatusPalette } from "../../Styles";

interface OrderStatusChipProps {
  status: OrderStatus;
}

const OrderStatusDisplay = {
  [OrderStatus.Open]: "Open",
  [OrderStatus.Paid]: "Paid",
  [OrderStatus.Completed]: "Completed",
  [OrderStatus.Expired]: "Expired",
  [OrderStatus.PaymentFailed]: "Failed",
};

const OrderStatusIcon = (status: OrderStatus): ReactElement => {
  switch (status) {
    case OrderStatus.Open:
      return <ErrorIcon />;
    case OrderStatus.Paid:
      return <PaidIcon />;
    case OrderStatus.Completed:
      return <CheckCircleIcon />;
    case OrderStatus.Expired:
      return <TimerOffIcon />;
    case OrderStatus.PaymentFailed:
      return <CancelIcon />;
  }
};

export const OrderStatusChip: FC<OrderStatusChipProps> = ({ status }) => (
  <Chip
    icon={OrderStatusIcon(status)}
    label={OrderStatusDisplay[status]}
    sx={{
      backgroundColor: OrderStatusPalette[status],
      fontWeight: "bold",
    }}
  />
);
