import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FailureResponse,
  ImageUploadField,
  Loading,
  SocialLinksField,
  SuccessResponse,
} from "../common";
import {
  APIResponse,
  CreateEstablishment,
  GetAreas,
  GetCuisines,
} from "../../api";
import { getCurrentUser } from "../../services";
import { PATHS } from "../../Constants";
import { IArea, ICity, ICuisine, ISocialLink } from "@skuare/common";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  area: Yup.string().required("Required"),
  location: Yup.object().shape({
    latitude: Yup.number().required("Required"),
    longitude: Yup.number().required("Required"),
  }),
});

export const AddEstablishment = () => {
  const [areas, setAreas] = useState<IArea[]>([]);
  const [cuisines, setCuisines] = useState<ICuisine[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedArea, setSelectedArea] = useState<IArea>();
  const [selectedCuisines, setSelectedCuisines] = useState<ICuisine[]>([]);
  const [apiResponse, setAPIResponse] = useState<APIResponse>({
    status: null,
    data: null,
    error: null,
  });

  useEffect(() => {
    const getData = async () => {
      const areaData = await GetAreas({ limit: 1000 }, getCurrentUser());
      const cuisineData = await GetCuisines({ limit: 1000 }, getCurrentUser());
      setAreas(areaData.results);
      setCuisines(cuisineData.results);
      setIsLoading(false);
    };

    getData();
  }, []);

  return apiResponse.status === "success" ? (
    <SuccessResponse
      message="Establishment Added Successfully"
      id={apiResponse.data.id}
      viewAllPath={PATHS.ESTABLISHMENT_LIST}
    />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Add Establishment
        </Typography>
        <Formik
          initialValues={{
            name: "",
            address: "",
            area: "",
            gmapUrl: "",
            socialLinks: [],
            cuisines: [],
            logo: "",
            parking: false,
            valet: false,
            liquor: false,
            isActive: true,
            location: {
              latitude: 0,
              longitude: 0,
            },
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = await CreateEstablishment(
                values,
                await getCurrentUser()
              );
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "success",
                data,
              }));
            } catch (error) {
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "failure",
                error,
              }));
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <Field
                        component={ImageUploadField}
                        id="establishment-add-logo"
                        name="logo"
                        label="Establishment Logo"
                        value={values.logo}
                        onChange={async (value: string) => {
                          setFieldTouched("logo");
                          await setFieldValue("logo", value);
                        }}
                        error={touched.logo && !!errors.logo}
                      />
                    </div>
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <TextField
                        id="establishment-add-name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        label="Name"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        required={true}
                        onFocus={() => setFieldTouched("name")}
                      />
                    </div>

                    <div>
                      <Field
                        component={Autocomplete}
                        loading={isLoading}
                        style={{ width: "100%" }}
                        disablePortal
                        id="event-add-area"
                        options={areas}
                        value={selectedArea}
                        onChange={async (event: any, option: IArea) => {
                          setFieldTouched("area");
                          if (option) {
                            setSelectedArea(option);
                            await setFieldValue("area", option.id);
                          } else {
                            setSelectedArea(undefined);
                            await setFieldValue("area", "");
                          }
                        }}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            {...params}
                            name="area"
                            label="Area"
                            error={touched["area"] && !!errors["area"]}
                            onTouchEnd={() => setFieldTouched("area")}
                            required={true}
                          />
                        )}
                        isOptionEqualToValue={(option: IArea, value: IArea) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option: IArea) =>
                          `${option.name}, ${option.pinCode}, ${
                            (option.city as ICity).name
                          }`
                        }
                        getOptionDisabled={(option: IArea) => !option.isActive}
                      />
                    </div>

                    <div>
                      <TextField
                        id="establishment-add-address"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        label="Address"
                        error={touched.address && !!errors.address}
                        helperText={touched.address && errors.address}
                        required={true}
                        onFocus={() => setFieldTouched("address")}
                      />
                    </div>

                    <div>
                      <TextField
                        id="establishment-add-gmapUrl"
                        name="gmapUrl"
                        value={values.gmapUrl}
                        onChange={handleChange}
                        label="Gmap URL"
                        error={touched.gmapUrl && !!errors.gmapUrl}
                        helperText={touched.gmapUrl && errors.gmapUrl}
                        onFocus={() => setFieldTouched("gmapUrl")}
                      />
                    </div>

                    <div>
                      <SocialLinksField
                        id="establishment-add-social-links"
                        value={values.socialLinks}
                        onChange={(event: any, value: ISocialLink[]) =>
                          setFieldValue("socialLinks", value)
                        }
                      />
                    </div>

                    <div>
                      <Field
                        component={Autocomplete}
                        loading={isLoading}
                        style={{ width: "100%" }}
                        disablePortal
                        multiple
                        id="establishment-add-cuisines"
                        options={cuisines}
                        sx={{ width: 300 }}
                        value={selectedCuisines}
                        onChange={async (
                          event: React.SyntheticEvent<Element>,
                          options: ICuisine[]
                        ) => {
                          setFieldTouched("cuisines");
                          if (options) {
                            setSelectedCuisines(options);
                            await setFieldValue(
                              "cuisines",
                              options.map((_: ICuisine) => _.id)
                            );
                          } else {
                            setSelectedCuisines([]);
                            await setFieldValue("cuisines", []);
                          }
                        }}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            {...params}
                            name="cuisines"
                            label="Cuisines"
                            error={touched["cuisines"] && !!errors["cuisines"]}
                            onTouchEnd={() => setFieldTouched("cuisines")}
                          />
                        )}
                        isOptionEqualToValue={(
                          option: ICuisine,
                          value: ICuisine
                        ) => option.id === value.id}
                        getOptionLabel={(option: ICuisine) => option.name}
                        getOptionDisabled={(option: ICuisine) =>
                          !option.isActive
                        }
                      />
                    </div>

                    <Stack direction="row" spacing={1}>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="establishment-add-latitude"
                            name="location.latitude"
                            type="number"
                            value={values.location.latitude}
                            onChange={handleChange}
                            label="Latitude"
                            error={
                              touched.location?.latitude &&
                              !!errors.location?.latitude
                            }
                            helperText={
                              touched.location?.latitude &&
                              errors.location?.latitude
                            }
                            required={true}
                            onFocus={() => setFieldTouched("location.latitude")}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="establishment-add-longitude"
                            name="location.longitude"
                            type="number"
                            value={values.location.longitude}
                            onChange={handleChange}
                            label="Longitude"
                            error={
                              touched.location?.longitude &&
                              !!errors.location?.longitude
                            }
                            helperText={
                              touched.location?.longitude &&
                              errors.location?.longitude
                            }
                            required={true}
                            onFocus={() =>
                              setFieldTouched("location.longitude")
                            }
                          />
                        </div>
                      </Grid>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="establishment-add-parking"
                                name="parking"
                                checked={values.parking}
                                value={values.parking}
                                onChange={handleChange}
                              />
                            }
                            label="Parking"
                            labelPlacement="start"
                            name="parking"
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="establishment-add-valet"
                                name="valet"
                                checked={values.valet}
                                value={values.valet}
                                onChange={handleChange}
                              />
                            }
                            label="Valet"
                            labelPlacement="start"
                            name="valet"
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="establishment-add-liquor"
                                name="liquor"
                                checked={values.liquor}
                                value={values.liquor}
                                onChange={handleChange}
                              />
                            }
                            label="Liquor"
                            labelPlacement="start"
                            name="liquor"
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="establishment-add-isActive"
                                name="isActive"
                                checked={values.isActive}
                                value={values.isActive}
                                onChange={handleChange}
                              />
                            }
                            label="Active"
                            labelPlacement="start"
                            name="isActive"
                          />
                        </FormControl>
                      </div>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loading isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Box>
      {apiResponse.status === "failure" ? (
        <FailureResponse error={apiResponse.error} />
      ) : null}
    </>
  );
};
