import React, {FormEvent, useState} from "react"
import {loginWithFirebase, post} from "../../services"
import {PATHS, URLS} from "../../Constants"
import {useNavigate} from "react-router-dom"
import {Box, Button, TextField} from "@mui/material"

export const Login = () => {
    const [value, setValue] = useState({
        loginEmail: "",
        loginPassword: "",
    })

    const navigate = useNavigate()

    const handleChange = (e: any) => {
        if (e?.target?.name && e?.target?.value) {
            setValue((prevState) => ({
                ...prevState,
                [e.target.name]: e.target?.type === "checkbox" ? e.target.checked : e.target.value,
            }))
        } else {
            setValue((prevState) => ({
                ...prevState,
                ...e,
            }))
        }
    }

    const handleSubmit = async (e: any) => {
        console.log(value)
        e.preventDefault()
        await loginWithFirebase(value.loginEmail, value.loginPassword)
        await post(URLS.ADMIN_LOGIN, {}, true)
        navigate(PATHS.EVENT_LIST.replace(":page", "1"))
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '40ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <h1>Login</h1>
            <div>
            <TextField
                id="email"
                name="loginEmail"
                label="Email"
                maxRows={10}
                onChange={handleChange}
                type="email"
            />
            </div>
            <div>
            <TextField
                id="password"
                name="loginPassword"
                label="Password"
                maxRows={4}
                onChange={handleChange}
                type="password"
            />
            </div>
            <Button variant="contained" onClick={handleSubmit}>Login</Button>
        </Box>
    )
}