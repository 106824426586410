import { DataContainer } from "../common";
import { IArtist, ISocialLink } from "@skuare/common";
import { GetArtists } from "../../api";
import { PATHS } from "../../Constants";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { Avatar, AvatarGroup, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { GetPathUrl } from "../../utils";

export const ArtistList = () => {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "imageUrl",
      headerName: "Avatar",
      flex: 0.5,
      renderCell: (params) => (
        <Avatar alt={params.row.stageName} src={params.value} />
      ),
    },
    {
      field: "stageName",
      headerName: "Stage Name",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
    },
    {
      field: "isVerified",
      headerName: "Verified",
      flex: 0.5,
      type: "boolean",
    },
    {
      field: "artistType",
      headerName: "Artist Type",
      flex: 1,
      renderCell: (params) => <Chip label={params.value} variant="filled" />,
    },
    {
      field: "isActive",
      headerName: "Active",
      type: "boolean",
      flex: 0.5,
      editable: true,
    },
    {
      field: "socialLinks",
      headerName: "Social Links",
      flex: 1,
      renderCell: (params) => {
        return (
          <AvatarGroup max={3}>
            {params.value.map((socialLink: ISocialLink) => (
              <Avatar
                alt={socialLink.platform}
                src={socialLink.platformIconUrl}
              />
            ))}
          </AvatarGroup>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => navigate(GetPathUrl(PATHS.ARTIST_EDIT, { id }))}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <DataContainer<IArtist>
      header="Artist"
      GetData={GetArtists}
      columns={columns}
      path={PATHS.ARTIST_LIST}
      columnVisibilityModel={{
        id: false,
      }}
      addNew={PATHS.ARTIST_ADD}
    />
  );
};
