import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FailureResponse,
  ImageUploadField,
  Loading,
  SuccessResponse,
} from "../common";
import { APIResponse, CreateCity } from "../../api";
import { getCurrentUser } from "../../services";
import { PATHS } from "../../Constants";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  imageUrl: Yup.string().required("Required"),
  location: Yup.object().shape({
    latitude: Yup.number().required("Required"),
    longitude: Yup.number().required("Required"),
  }),
});

export const AddCity = () => {
  const [apiResponse, setAPIResponse] = useState<APIResponse>({
    status: null,
    data: null,
    error: null,
  });

  return apiResponse.status === "success" ? (
    <SuccessResponse
      message="City Added Successfully"
      id={apiResponse.data.id}
      viewAllPath={PATHS.CITY_LIST}
    />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Add City
        </Typography>
        <Formik
          initialValues={{
            name: "",
            imageUrl: "",
            isActive: true,
            location: {
              latitude: 0,
              longitude: 0,
            },
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = await CreateCity(values, await getCurrentUser());
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "success",
                data,
              }));
            } catch (error) {
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "failure",
                error,
              }));
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <Field
                        component={ImageUploadField}
                        id="city-add-imageUrl"
                        name="imageUrl"
                        label="City Logo"
                        value={values.imageUrl}
                        onChange={async (value: string) => {
                          setFieldTouched("imageUrl");
                          await setFieldValue("imageUrl", value);
                        }}
                        multi={false}
                        accepts={["image/*"]}
                        error={touched.imageUrl && !!errors.imageUrl}
                      />
                    </div>
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <TextField
                        id="city-add-name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        label="Name"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        required={true}
                        onFocus={() => setFieldTouched("name")}
                      />
                    </div>

                    <Stack direction="row" spacing={1}>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="city-add-latitude"
                            name="location.latitude"
                            type="number"
                            value={values.location.latitude}
                            onChange={handleChange}
                            label="Latitude"
                            error={
                              touched.location?.latitude &&
                              !!errors.location?.latitude
                            }
                            helperText={
                              touched.location?.latitude &&
                              errors.location?.latitude
                            }
                            required={true}
                            onFocus={() => setFieldTouched("location.latitude")}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="city-add-longitude"
                            name="location.longitude"
                            type="number"
                            value={values.location.longitude}
                            onChange={handleChange}
                            label="Longitude"
                            error={
                              touched.location?.longitude &&
                              !!errors.location?.longitude
                            }
                            helperText={
                              touched.location?.longitude &&
                              errors.location?.longitude
                            }
                            required={true}
                            onFocus={() =>
                              setFieldTouched("location.longitude")
                            }
                          />
                        </div>
                      </Grid>
                    </Stack>

                    <div>
                      <FormControl sx={{ ml: -1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="city-add-isActive"
                              name="isActive"
                              checked={values.isActive}
                              value={values.isActive}
                              onChange={handleChange}
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                          name="isActive"
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loading isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Box>
      {apiResponse.status === "failure" ? (
        <FailureResponse error={apiResponse.error} />
      ) : null}
    </>
  );
};
