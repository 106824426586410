import React, { useState } from "react";
import {
  EventByType,
  EventType,
  IAggregatedEvent,
  IArtist,
  ICategory,
  ICuisine,
  IOrganizer,
} from "@skuare/common";
import { AddEventStep1 } from "./AddEventStep1";
import { AddEventStep2 } from "./AddEventStep2";
import { AddEventStep3 } from "./AddEventStep3";
import { AddEventStep4 } from "./AddEventStep4";
import { AddEventStep5 } from "./AddEventStep5";
import { AddEventStep6 } from "./AddEventStep6";
import {
  Box,
  Button,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import { getCurrentUser } from "../../services";
import { CreateEvent } from "../../api";
import { FailureResponse, Loading, SuccessResponse } from "../common";
import { PATHS } from "../../Constants";

const steps = [
  "City",
  "Organizer",
  "Event Details",
  "Multi Media",
  "Tickets",
  "Preview",
];

const initialValue = {
  id: "",
  title: "",
  description: "",
  address: "",
  gmapUrl: "",
  area: "",
  socialLinks: [],
  cuisines: [],
  multimedia: [],
  eventByDisplay: "",
  eventByType: EventByType.Establishment,
  artists: [],
  typeDisplay: "",
  tickets: [],
  isActive: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  venue: {
    name: "",
    address: "",
  },
  categories: [],
  slug: "",
  popularity: 0,
  priority: 0,
  viewCount: 0,
  bookedCount: 0,
  favouritesCount: 0,
  organizers: [],
  city: "",
  type: EventType.BrunchEvent,
  hostAtEstablishment: true,
  tnc:
    "Entry is restricted to mentioned age group only.\n" +
    "\n" +
    "Please carry a valid government ID proof along with you.\n" +
    "\n" +
    "Please furnish your fully vaccinated certificate (a print or a copy on phone) whenever requested.\n" +
    "\n" +
    "No refunds on purchased ticket are possible, even in case of any rescheduling.\n" +
    "\n" +
    "Security procedures, including frisking remain the right of the management.\n" +
    "\n" +
    "No dangerous or potentially hazardous objects including but not limited to weapons, knives, guns, fireworks, helmets, lazer devices, bottles, musical instruments will be allowed in the venue and may be ejected with or without the owner from the venue.\n" +
    "\n" +
    "The sponsors/performers/organizers are not responsible for any injury or damage occurring due to the event. Any claims regarding the same would be settled in courts in Mumbai.\n" +
    "\n" +
    "People in an inebriated state may not be allowed entry.\n" +
    "\n" +
    "Organizers hold the right to deny late entry to the event.\n" +
    "\n" +
    "No bags or Luggage Permitted inside the venue.\n" +
    "\n" +
    "Parking at Owner's risk\n" +
    "\n" +
    "No outside food or drinks allowed\n" +
    "\n" +
    "Right to admission reserved by organizers\n" +
    "\n" +
    "Venue rules apply.\n" +
    "\n" +
    "Tickets are non-cancellable, non-refundable and non-transferable.\n" +
    "\n" +
    "Guest List may shut earlier than the mentioned time, once it is full\n" +
    "\n" +
    "Entry must be no later than the time on your ticket\n" +
    "\n" +
    "Cover charges applicable at the gate, post entry time\n" +
    "\n" +
    "Following the establishment’s defined dress code is a must.\n" +
    "\n" +
    "Internet handling charges per ticket may be applicable. Please check the final amount\n" +
    "\n",
};

export const AddEvent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [inValidStep, setInValidStep] = useState(new Set<number>([0, 1, 2, 3]));
  const [value, setValue] = useState(initialValue);
  const [selectedCity, setSelectedCity] = useState();
  const [selectedEstablishmentOption, setSelectedEstablishmentOption] =
    useState();
  const [selectedEventManagerOption, setSelectedEventManagerOption] =
    useState();
  const [selectedEventSpaceOption, setSelectedEventSpaceOption] = useState();
  const [selectedArea, setSelectedArea] = useState();

  const [selectedOrganizers, setSelectedOrganizers] = useState<IOrganizer[]>(
    []
  );
  const [selectedArtists, setSelectedArtists] = useState<IArtist[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<ICategory[]>([]);
  const [selectedCuisines, setSelectedCuisines] = useState<ICuisine[]>([]);
  const [createdEvent, setCreatedEvent] = useState<
    IAggregatedEvent | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(false);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const isStepInValid = (step: number) => {
    return inValidStep.has(step);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const data = await CreateEvent(value, getCurrentUser());
      setCreatedEvent(data);
      setIsLoading(false);
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      await handleSubmit();
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event: any) => {
    if (event.target.type === "checkbox") {
      setValue((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    } else if (event.target.value) {
      setValue((prevState) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleEventByTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    eventByType: EventByType
  ) => {
    setValue((prevState) => ({
      ...prevState,
      eventByType,
      hostAtEstablishment: eventByType === EventByType.Establishment,
    }));

    setInValidStep((prevState: Set<number>) => {
      return new Set([...prevState, 1]);
    });
  };

  const onChange = (event: any, value: any, name: string) =>
    setValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  const getComponent = () => {
    if (activeStep === 0)
      return (
        <AddEventStep1
          value={value}
          handleChange={handleChange}
          handleEventByTypeChange={handleEventByTypeChange}
          onChange={onChange}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 1)
      return (
        <AddEventStep2
          value={value}
          handleChange={handleChange}
          onChange={onChange}
          selectedEstablishmentOption={selectedEstablishmentOption}
          setSelectedEstablishmentOption={setSelectedEstablishmentOption}
          selectedEventManagerOption={selectedEventManagerOption}
          setSelectedEventManagerOption={setSelectedEventManagerOption}
          selectedEventSpaceOption={selectedEventSpaceOption}
          setSelectedEventSpaceOption={setSelectedEventSpaceOption}
          selectedArea={selectedArea}
          setSelectedArea={setSelectedArea}
          selectedOrganizers={selectedOrganizers}
          setSelectedOrganizers={setSelectedOrganizers}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 2)
      return (
        <AddEventStep3
          value={value}
          handleChange={handleChange}
          onChange={onChange}
          selectedArtists={selectedArtists}
          setSelectedArtists={setSelectedArtists}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          selectedCuisines={selectedCuisines}
          setSelectedCuisines={setSelectedCuisines}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 3)
      return (
        <AddEventStep4
          value={value}
          onChange={onChange}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 4)
      return (
        <AddEventStep5
          value={value}
          onChange={onChange}
          setInValidStep={setInValidStep}
        />
      );
    else if (activeStep === 5)
      return (
        <AddEventStep6
          value={value}
          selectedCity={selectedCity}
          selectedEstablishmentOption={selectedEstablishmentOption}
          selectedEventManagerOption={selectedEventManagerOption}
          selectedEventSpaceOption={selectedEventSpaceOption}
          selectedArea={selectedArea}
          selectedOrganizers={selectedOrganizers}
          selectedArtists={selectedArtists}
          selectedCategories={selectedCategories}
          selectedCuisines={selectedCuisines}
        />
      );
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <h1>Add Event</h1>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean; disabled?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step key={label} {...stepProps}>
                <StepButton {...labelProps}>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
        {getComponent()}
        {activeStep === steps.length ? (
          <React.Fragment>
            {error ? (
              <FailureResponse error={error} />
            ) : (
              <SuccessResponse
                id={createdEvent?.id}
                message="Event Added Successfully"
                viewAllPath={PATHS.EVENT_LIST}
                shareUrl={createdEvent?.shareUrl}
                viewExternalUrl={createdEvent?.shareUrl}
              />
            )}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                variant="contained"
                disabled={isStepInValid(activeStep)}
              >
                {activeStep === steps.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Loading isLoading={isLoading} />
    </>
  );
};
