import { FC } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

interface FailureResponseProps {
  error: any;
  message?: string;
}
export const FailureResponse: FC<FailureResponseProps> = ({
  error,
  message = "Error Encountered",
}) => {
  return (
    <Grid container justifyContent="center">
      <Grid item md={4} justifyContent="center" alignItems="center">
        <Box sx={{ paddingTop: "50px" }}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h4">{message}</Typography>
            <ErrorIcon color="error" fontSize="large" />
          </Stack>
        </Box>
      </Grid>
      <Grid item md={12}>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </Grid>
    </Grid>
  );
};
