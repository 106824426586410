import { Alert, Snackbar } from "@mui/material";
import React, { FC } from "react";
import { AlertColor } from "@mui/material/Alert/Alert";

interface SnackBarProps {
  open: boolean;
  handleClose: () => void;
  message: string;
  severity?: AlertColor;
}
export const SnackBar: FC<SnackBarProps> = ({
  open,
  handleClose,
  message,
  severity = "info",
}) => (
  <Snackbar
    open={open}
    autoHideDuration={5000}
    onClose={handleClose}
    message={message}
    anchorOrigin={{ vertical: "top", horizontal: "right" }}
  >
    <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
      {message}
    </Alert>
  </Snackbar>
);
