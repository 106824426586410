import { GetEstablishments } from "../../api";
import { IEstablishment, ISocialLink } from "@skuare/common";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { DataContainer } from "../common";
import { PATHS } from "../../Constants";
import { Avatar, AvatarGroup } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { GetPathUrl } from "../../utils";

export const EstablishmentList = () => {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 2,
    },
    {
      field: "area",
      headerName: "Area",
      flex: 1,
      sortComparator: (v1, v2) => (v1.name < v2.name ? -1 : 1),
      valueFormatter: (params) => `${params.value.name}`,
    },
    {
      field: "isActive",
      headerName: "Active",
      type: "boolean",
      flex: 0.5,
      editable: true,
    },
    {
      field: "socialLinks",
      headerName: "Social Links",
      flex: 0.5,
      valueFormatter: (params) => params.value.length,
      renderCell: (params) => {
        return (
          <AvatarGroup max={3}>
            {params.value.map((socialLink: ISocialLink) => (
              <Avatar
                alt={socialLink.platform}
                src={socialLink.platformIconUrl}
              />
            ))}
          </AvatarGroup>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created",
      type: "dateTime",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      type: "dateTime",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() =>
              navigate(GetPathUrl(PATHS.ESTABLISHMENT_EDIT, { id }))
            }
            color="inherit"
          />,
        ];
      },
    },
  ];

  const columnVisibilityModel = {
    id: false,
    createdAt: false,
  };

  return (
    <DataContainer<IEstablishment>
      header="Establishment"
      GetData={GetEstablishments}
      columns={columns}
      path={PATHS.ESTABLISHMENT_LIST}
      columnVisibilityModel={columnVisibilityModel}
      addNew={PATHS.ESTABLISHMENT_ADD}
    />
  );
};
