import { URLS } from "../Constants";
import axios from "axios";
import { IArea, IPaginatedResponse } from "@skuare/common";
import { GetApiUrl } from "../utils";
import { User } from "firebase/auth";

export const GetAreas = async (
  queryParams: { [key: string]: string | number },
  user: User | null
) => {
  return axios
    .get<IPaginatedResponse<IArea>>(
      GetApiUrl(URLS.AREA_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);
};

export const GetArea = (areaId: string) =>
  axios
    .get<IArea>(GetApiUrl(URLS.AREA_DETAIL, { id: areaId }))
    .then((resp) => resp.data);

export const CreateArea = async (area: Partial<IArea>, user: User | null) => {
  try {
    const { data } = await axios.post<Partial<IArea>>(
      GetApiUrl(URLS.AREA_ADD),
      area,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
