import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Grid,
  ImageList,
  ImageListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { FC, useState } from "react";
import { ArtistChip, ArtistChipProps } from "../Artist";
import {
  LiquorIconChip,
  OrganizerChip,
  OrganizerChipProps,
  ParkingIconChip,
  SocialLinkChip,
  SocialLinkChipProps,
  StackRow,
  ValetIconChip,
} from "../common";
import {
  EventByType,
  IArea,
  IArtist,
  ICategory,
  ICuisine,
  IMultiMedia,
  IOrganizer,
  ISocialLink,
  ITicketItem,
  MultiMediaType,
  ShowDateRange,
} from "@skuare/common";
import { EventTypeChip } from "./EventTypeChip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageIcon from "@mui/icons-material/Image";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Rupee } from "../../Constants";
import { ChipProps } from "@mui/material/Chip/Chip";

interface EditEventStep6Props {
  value: any;
  selectedCity: any;
  selectedEstablishmentOption: any;
  selectedEventManagerOption: any;
  selectedEventSpaceOption: any;
  selectedArea: any;
  selectedOrganizers: IOrganizer[];
  selectedArtists: IArtist[] | string[];
  selectedCategories: ICategory[] | string[];
  selectedCuisines: ICuisine[] | string[];
}

export const EditEventStep6: FC<EditEventStep6Props> = ({
  value,
  selectedCity,
  selectedEstablishmentOption,
  selectedEventManagerOption,
  selectedEventSpaceOption,
  selectedArea,
  selectedOrganizers,
  selectedArtists,
  selectedCategories,
  selectedCuisines,
}) => {
  const [expanded, setExpanded] = useState<string | false>("description");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Grid container justifyContent="space-around">
      <Grid item md={5}>
        <h2>Preview</h2>
        <Table component={Paper}>
          <TableRow>
            <TableCell variant="head">Title</TableCell>
            <TableCell>{value.title}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Date and Time</TableCell>
            <TableCell>
              {ShowDateRange(
                new Date(value.startDateTime),
                new Date(value.endDateTime)
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">City</TableCell>
            <TableCell>{selectedCity.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Area</TableCell>
            <TableCell>{selectedArea}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Venue</TableCell>
            <TableCell>
              {value.hostAtEstablishment
                ? `${selectedEstablishmentOption.name}, ${
                    (selectedEstablishmentOption.area as IArea).name
                  }`
                : `${selectedEventSpaceOption.name}, ${
                    (selectedEventSpaceOption.area as IArea).name
                  }`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Event Type</TableCell>
            <TableCell>
              <EventTypeChip type={value.type} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Event By</TableCell>
            <TableCell>{value.eventByDisplay}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Organizers</TableCell>
            <TableCell>
              <StackRow<OrganizerChipProps>
                items={[
                  {
                    organizer: {
                      type: value.eventByType,
                      by:
                        value.eventByType === EventByType.Establishment
                          ? selectedEstablishmentOption
                          : selectedEventManagerOption,
                    },
                  },
                  ...selectedOrganizers.map((organizer: IOrganizer) => ({
                    organizer,
                  })),
                ]}
                ItemRender={OrganizerChip}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Artists</TableCell>
            <TableCell>
              <StackRow<ArtistChipProps>
                items={selectedArtists.map((artist) => ({
                  artist: artist as IArtist,
                }))}
                ItemRender={ArtistChip}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Social Links</TableCell>
            <TableCell>
              <StackRow<SocialLinkChipProps>
                items={value.socialLinks.map((socialLink: ISocialLink) => ({
                  socialLink,
                }))}
                ItemRender={SocialLinkChip}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Cuisines</TableCell>
            <TableCell>
              <StackRow<ChipProps>
                items={selectedCuisines.map((cuisine) => ({
                  label: (cuisine as ICategory).name,
                }))}
                ItemRender={Chip}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Categories</TableCell>
            <TableCell>
              <StackRow<ChipProps>
                items={selectedCategories.map((category) => ({
                  label: (category as ICategory).name,
                }))}
                ItemRender={Chip}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Others</TableCell>
            <TableCell>
              <Stack direction="row" spacing={1}>
                {value.parking ? <ParkingIconChip /> : null}
                {value.valet ? <ValetIconChip /> : null}
                {value.liquor ? <LiquorIconChip /> : null}
              </Stack>
            </TableCell>
          </TableRow>
        </Table>
      </Grid>
      <Grid item md={5}>
        <h2>&nbsp;</h2>
        <Accordion
          expanded={expanded === "description"}
          onChange={handleChange("description")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Description
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{value.description}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "media"}
          onChange={handleChange("media")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Media</Typography>
            <Grid container justifyContent="space-evenly">
              <Grid item>
                <Chip
                  icon={<ImageIcon />}
                  label={`${
                    value.multimedia.filter(
                      (m: IMultiMedia) => m.type === MultiMediaType.Image
                    ).length
                  } Image(s)`}
                />
              </Grid>
              <Grid item>
                <Chip
                  icon={<OndemandVideoIcon />}
                  label={`${
                    value.multimedia.filter(
                      (m: IMultiMedia) => m.type === MultiMediaType.Video
                    ).length
                  } Video(s)`}
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <ImageList sx={{ width: "100%" }} cols={3}>
              {value.multimedia.map((item: IMultiMedia) => (
                <ImageListItem key={item.url}>
                  <img src={item.url} alt={value.title} loading="lazy" />
                </ImageListItem>
              ))}
            </ImageList>
          </AccordionDetails>
        </Accordion>
        {value.tickets.length ? (
          <Accordion
            expanded={expanded === "tickets"}
            onChange={handleChange("tickets")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Tickets
              </Typography>
              <Grid container justifyContent="space-evenly">
                {value.tickets.map((ticket: ITicketItem) => (
                  <Grid item>
                    <Chip
                      label={`${ticket.ticket.ticketName} (${
                        ticket.ticket.price
                          ? `${Rupee}${ticket.ticket.price}`
                          : "RSVP"
                      })`}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableCell>Ticket Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Last Booking</TableCell>
                  <TableCell>Validity</TableCell>
                  <TableCell>Entry For</TableCell>
                </TableHead>
                <TableBody>
                  {value.tickets.map((ticket: ITicketItem) => (
                    <TableRow>
                      <TableCell>{ticket.ticket.ticketName}</TableCell>
                      <TableCell>{ticket.ticket.price}</TableCell>
                      <TableCell>{ticket.totalQuantity}</TableCell>
                      <TableCell>
                        {new Date(
                          ticket.ticket.lastBookingTime
                        ).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {ShowDateRange(
                          new Date(ticket.ticket.validFrom),
                          new Date(ticket.ticket.validTo)
                        )}
                      </TableCell>
                      <TableCell>Male, Female, Couple</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </Grid>
    </Grid>
  );
};
