import { FC } from "react";
import { Avatar, Chip } from "@mui/material";
import { IArtist } from "@skuare/common";

export interface ArtistChipProps {
  artist: IArtist;
}
export const ArtistChip: FC<ArtistChipProps> = ({ artist, ...props }) => (
  <Chip
    avatar={
      <Avatar
        alt={artist.stageName}
        src={artist.imageUrl}
        className={artist.isVerified ? "artist-chip-verified" : ""}
      />
    }
    label={artist.stageName}
    variant="outlined"
    {...props}
  />
);
