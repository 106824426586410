import { IOrder } from "@skuare/common";
import { GridColDef } from "@mui/x-data-grid";
import { DataContainer } from "../common";
import { PATHS } from "../../Constants";
import { GetOrders } from "../../api";
import { OrderStatusChip } from "../common";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "user",
    headerName: "User",
    flex: 1.5,
    valueGetter: (params) =>
      `${params.value.fullName}, ${params.value.email} ${params.value.phoneNumber}`,
  },
  {
    field: "shortCode",
    headerName: "Short Code",
    flex: 0.5,
  },
  {
    field: "event",
    headerName: "Event",
    flex: 1,
    valueGetter: (params) =>
      `${params.value.title}, ${params.value.venue.name}`,
  },
  {
    field: "total",
    headerName: "Total",
    flex: 0.5,
    valueGetter: (params) => `₹${params.value} [${params.row.tickets.length}]`,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.5,
    renderCell: (params) => <OrderStatusChip status={params.value} />,
  },
  {
    field: "purchasedOn",
    headerName: "Purchased On",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "createdAt",
    headerName: "Created",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
];

export const OrderList = () => (
  <DataContainer<IOrder>
    header="Order"
    GetData={GetOrders}
    columns={columns}
    path={PATHS.ORDER_LIST}
    columnVisibilityModel={{
      id: false,
      createdAt: false,
      updatedAt: false,
    }}
  />
);
