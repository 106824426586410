import * as React from "react";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EventIcon from "@mui/icons-material/Event";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import NightlifeIcon from "@mui/icons-material/Nightlife";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import StadiumIcon from "@mui/icons-material/Stadium";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import AlbumIcon from "@mui/icons-material/Album";
import SecurityIcon from "@mui/icons-material/Security";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CategoryIcon from "@mui/icons-material/Category";
import MessageIcon from "@mui/icons-material/Message";
import { PATHS } from "../../Constants";
import { useNavigate } from "react-router-dom";
import { GetPathUrl } from "../../utils";

const primaryOptions = [
  {
    label: "Event",
    icon: <EventIcon />,
    link: GetPathUrl(PATHS.EVENT_LIST, { page: "1" }),
  },
  {
    label: "City",
    icon: <LocationCityIcon />,
    link: GetPathUrl(PATHS.CITY_LIST, { page: "1" }),
  },
  {
    label: "Area",
    icon: <MapIcon />,
    link: GetPathUrl(PATHS.AREA_LIST, { page: "1" }),
  },
  {
    label: "Establishment",
    icon: <NightlifeIcon />,
    link: GetPathUrl(PATHS.ESTABLISHMENT_LIST, { page: "1" }),
  },
  {
    label: "Event Manager",
    icon: <PermContactCalendarIcon />,
    link: GetPathUrl(PATHS.EVENT_MANAGER_LIST, { page: "1" }),
  },
  {
    label: "Gate Keeper",
    icon: <SecurityIcon />,
    link: GetPathUrl(PATHS.GATE_KEEPER_LIST, { page: "1" }),
  },
  {
    label: "Event Space",
    icon: <StadiumIcon />,
    link: GetPathUrl(PATHS.EVENTSPACE_LIST, { page: "1" }),
  },
  {
    label: "Artist",
    icon: <AlbumIcon />,
    link: GetPathUrl(PATHS.ARTIST_LIST, { page: "1" }),
  },
  {
    label: "Offer",
    icon: <LocalOfferIcon />,
    link: GetPathUrl(PATHS.OFFER_LIST, { page: "1" }),
  },
  {
    label: "Order",
    icon: <ConfirmationNumberIcon />,
    link: GetPathUrl(PATHS.ORDER_LIST, { page: "1" }),
  },
  {
    label: "Cuisine",
    icon: <RestaurantIcon />,
    link: GetPathUrl(PATHS.CUISINE_LIST, { page: "1" }),
  },
  {
    label: "Category",
    icon: <CategoryIcon />,
    link: GetPathUrl(PATHS.CATEGORY_LIST, { page: "1" }),
  },
  {
    label: "Message",
    icon: <MessageIcon />,
    link: PATHS.MESSAGE_LIST,
  },
];
const secondaryOptions = [
  {
    label: "Users",
    icon: <GroupIcon />,
    link: PATHS.EVENT_LIST,
  },
  {
    label: "Settings",
    icon: <SettingsIcon />,
    link: PATHS.EVENT_LIST,
  },
];

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Navigation = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon onClick={toggleDrawer} />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            SKUARE
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader />
        <Divider />
        <List>
          {primaryOptions.map((option, index) => (
            <ListItem
              key={option.link}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => navigate(option.link)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {option.icon}
                </ListItemIcon>
                <ListItemText
                  primary={option.label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {secondaryOptions.map((option, index) => (
            <ListItem
              key={option.link}
              disablePadding
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => navigate(option.link)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {option.icon}
                </ListItemIcon>
                <ListItemText
                  primary={option.label}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};
