import { User } from "firebase/auth";
import axios from "axios";
import { IMessage, IPaginatedResponse } from "@skuare/common";
import { GetApiUrl } from "../utils";
import { URLS } from "../Constants";

export const GetMessages = async (
  queryParams: { [key: string]: string | number },
  user: User | null
) => {
  return axios
    .get<IPaginatedResponse<IMessage>>(
      GetApiUrl(URLS.MESSAGE_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);
};
