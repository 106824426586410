import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Grid,
  TextField,
} from "@mui/material";
import {
  EventByType,
  IArea,
  ICity,
  IEstablishment,
  IEventManager,
  IEventSpace,
  IOrganizer,
} from "@skuare/common";

import { Formik, Form, Field } from "formik";
import { GetEstablishments, GetEventmanagers, GetEventSpaces } from "../../api";
import { getCurrentUser } from "../../services";
import { OrganizerChip } from "../common";

interface EditEventStep2Errors {
  eventBy: string;
  address: string;
  eventByDisplay: string;
  eventSpace: string;
}

interface EditEventStep2Props {
  value: any;
  handleChange: (e: any) => void;
  onChange: (e: React.SyntheticEvent, value: any, name: string) => void;
  selectedEstablishmentOption: any;
  setSelectedEstablishmentOption: (value: any) => void;
  selectedEventManagerOption: any;
  setSelectedEventManagerOption: (value: any) => void;
  selectedEventSpaceOption: any;
  setSelectedEventSpaceOption: (value: any) => void;
  selectedArea: any;
  setSelectedArea: (value: any) => void;
  selectedOrganizers: any;
  setSelectedOrganizers: (value: any) => void;
  setInValidStep: (value: (prevState: Set<number>) => Set<number>) => void;
}

export const EditEventStep2: FC<EditEventStep2Props> = ({
  value,
  handleChange,
  onChange,
  selectedEstablishmentOption,
  setSelectedEstablishmentOption,
  selectedEventManagerOption,
  setSelectedEventManagerOption,
  selectedEventSpaceOption,
  setSelectedEventSpaceOption,
  selectedArea,
  setSelectedArea,
  selectedOrganizers,
  setSelectedOrganizers,
  setInValidStep,
}) => {
  const [establishments, setEstablishments] = useState<IEstablishment[]>([]);
  const [eventManagers, setEventManagers] = useState<IEventManager[]>([]);
  const [eventSpaces, setEventSpaces] = useState<IEventSpace[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const establishmentData = await GetEstablishments(
        { limit: 1000, city: value.city },
        getCurrentUser()
      );
      const eventManagerData = await GetEventmanagers(
        { limit: 1000 },
        getCurrentUser()
      );

      if (!value.hostAtEstablishment) {
        const eventSpaceData = await GetEventSpaces(
          { limit: 1000 },
          getCurrentUser()
        );
        setEventSpaces(eventSpaceData.results);
      }

      setEstablishments(establishmentData.results);
      setEventManagers(eventManagerData.results);
      setIsLoading(false);
    };

    getData();
  }, []);

  return (
    <Formik
      initialValues={value}
      onSubmit={() => {}}
      validate={(rawValues) => {
        const errors: Partial<EditEventStep2Errors> = {};
        console.log("Validating", rawValues);
        if (!rawValues.eventBy) {
          errors.eventBy = "Required";
        }

        if (!rawValues.address) {
          errors.address = "Required";
        }

        if (!value.hostAtEstablishment && !rawValues.eventSpace) {
          errors.eventSpace = "Required";
        }

        if (Object.keys(errors).length > 0) {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState, 1]);
          });
        } else {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState].filter((v) => v !== 1));
          });
        }

        console.log("Errors with", errors, Object.keys(errors).length);

        return errors;
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Grid container justifyContent="center">
            <Grid item md={4}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <h2>Select Organizers</h2>

                {value.eventByType === EventByType.Establishment ? (
                  <div>
                    <Field
                      component={Autocomplete}
                      loading={isLoading}
                      style={{ width: "100%" }}
                      disablePortal
                      id="event-add-eventBy"
                      options={establishments}
                      value={selectedEstablishmentOption}
                      onChange={async (event: any, option: IEstablishment) => {
                        setFieldTouched("eventBy");
                        if (option) {
                          setSelectedEstablishmentOption(option);
                          setSelectedArea(
                            `${(option.area as IArea).name}, ${
                              (option.area as IArea).pinCode
                            }`
                          );
                          onChange(event, option.id, "eventBy");
                          onChange(event, (option.area as IArea).id, "area");
                          onChange(event, option.address, "address");
                          onChange(
                            event,
                            `${option.name}, ${(option.area as IArea).name}`,
                            "eventByDisplay"
                          );
                          onChange(event, option.socialLinks, "socialLinks");
                          onChange(event, option.parking, "parking");
                          onChange(event, option.valet, "valet");
                          onChange(event, option.liquor, "liquor");
                          onChange(event, option.gmapUrl, "gmapUrl");

                          await setFieldValue("eventBy", option.id, true);
                          await setFieldValue(
                            "eventByDisplay",
                            `${option.name}, ${(option.area as IArea).name}`
                          );
                          await setFieldValue("address", option.address);
                        } else {
                          await setFieldValue("eventBy", "");
                        }
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          name="eventBy"
                          label="Establishment"
                          helperText="Event is organized by Establishment"
                          error={touched.eventBy && !!errors.eventBy}
                          onFocus={() => setFieldTouched("eventBy")}
                          required={true}
                        />
                      )}
                      isOptionEqualToValue={(
                        option: IEstablishment,
                        value: IEstablishment
                      ) => option.id === value.id}
                      getOptionLabel={(option: IEstablishment) =>
                        `${option.name}, ${(option.area as IArea).name}, ${
                          ((option.area as IArea).city as ICity).name
                        }`
                      }
                      getOptionDisabled={(option: IEstablishment) =>
                        !option.isActive
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <Field
                      component={Autocomplete}
                      loading={isLoading}
                      style={{ width: "100%" }}
                      disablePortal
                      id="event-add-eventBy"
                      options={eventManagers}
                      sx={{ width: 300 }}
                      value={selectedEventManagerOption}
                      onChange={async (event: any, option: IEventManager) => {
                        await setFieldTouched("eventBy");
                        if (option) {
                          setSelectedEventManagerOption(option);
                          onChange(event, option.id, "eventBy");
                          onChange(
                            event,
                            `${option.nameOfPersonOfContact}, ${option.nameOfCompany}`,
                            "eventByDisplay"
                          );

                          await setFieldValue("eventBy", option.id);
                          await setFieldValue(
                            "eventByDisplay",
                            `${option.nameOfPersonOfContact}, ${option.nameOfCompany}`
                          );
                        } else {
                          await setFieldValue("eventBy", "");
                        }
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          name="eventBy"
                          label="Event Manager"
                          helperText="Event is organized by Event Manager"
                          error={touched.eventBy && !!errors.eventBy}
                          onFocus={() => setFieldTouched("eventBy")}
                          required={true}
                        />
                      )}
                      isOptionEqualToValue={(
                        option: IEventManager,
                        value: IEventManager
                      ) => option.id === value.id}
                      getOptionLabel={(option: IEventManager) =>
                        `${option.nameOfPersonOfContact}, ${option.nameOfCompany}`
                      }
                      getOptionDisabled={(option: IEventManager) =>
                        !option.isActive
                      }
                    />
                  </div>
                )}

                {value.hostAtEstablishment ? null : (
                  <div>
                    <Field
                      component={Autocomplete}
                      loading={isLoading}
                      style={{ width: "100%" }}
                      disablePortal
                      id="event-add-eventSpace"
                      options={eventSpaces}
                      sx={{ width: 300 }}
                      value={selectedEventSpaceOption}
                      onChange={async (event: any, option: IEventSpace) => {
                        await setFieldTouched("eventSpace");
                        if (option) {
                          setSelectedEventSpaceOption(option);
                          setSelectedArea(
                            `${(option.area as IArea).name}, ${
                              (option.area as IArea).pinCode
                            }`
                          );
                          onChange(event, option.id, "eventSpace");
                          onChange(event, (option.area as IArea).id, "area");
                          onChange(event, option.address, "address");
                          onChange(event, option.gmapUrl, "gmapUrl");

                          await setFieldValue("eventSpace", option.id);
                          await setFieldValue("address", option.address);
                        } else {
                          await setFieldValue("eventSpace", "");
                        }
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          label="Event Space"
                          name="eventSpace"
                          helperText="Event is not hosted at Establishment."
                          error={touched.eventSpace && !!errors.eventSpace}
                          onFocus={() => setFieldTouched("eventSpace")}
                          required={true}
                        />
                      )}
                      isOptionEqualToValue={(
                        option: IEventSpace,
                        value: IEventSpace
                      ) => option.id === value.id}
                      getOptionLabel={(option: IEventSpace) =>
                        `${option.name}, ${(option.area as IArea).name}, ${
                          ((option.area as IArea).city as ICity).name
                        }`
                      }
                      getOptionDisabled={(option: IEventSpace) =>
                        !option.isActive
                      }
                    />
                  </div>
                )}

                <div>
                  <TextField
                    id="event-add-area"
                    label="Area"
                    value={selectedArea}
                    onChange={handleChange}
                    disabled
                    helperText="Area is autoselected"
                  />
                </div>

                <div>
                  <TextField
                    id="event-add-address"
                    multiline
                    label="Address"
                    value={value.address}
                    name="address"
                    onChange={async (event: any) => {
                      handleChange(event);
                      setFieldTouched("address");
                      await setFieldValue("address", event.target.value);
                    }}
                    error={touched.address && !!errors.address}
                    required={true}
                  />
                </div>

                <div>
                  <TextField
                    id="event-add-eventByDisplay"
                    label="Event by Display"
                    value={value.eventByDisplay}
                    name="eventByDisplay"
                    onChange={async (event: any) => {
                      handleChange(event);
                      setFieldTouched("eventByDisplay");
                      await setFieldValue("eventByDisplay", event.target.value);
                    }}
                    error={touched.eventByDisplay && !!errors.eventByDisplay}
                    required={true}
                  />
                </div>

                <div>
                  <Autocomplete
                    loading={isLoading}
                    style={{ width: "100%" }}
                    disablePortal
                    multiple
                    id="event-add-organizers"
                    options={[
                      ...eventManagers.map((_) => ({
                        type: EventByType.EventManager,
                        by: _,
                      })),
                      ...establishments.map((_) => ({
                        type: EventByType.Establishment,
                        by: _,
                      })),
                    ]}
                    sx={{ width: 300 }}
                    value={selectedOrganizers}
                    onChange={(event, options) => {
                      if (options) {
                        console.log(options.map((_: IOrganizer) => _.by.id));
                        setSelectedOrganizers(options);
                        onChange(
                          event,
                          options.map((_: IOrganizer) => ({
                            ..._,
                            by: _.by.id,
                          })),
                          "organizers"
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="organizers"
                        label="Additional Organizers"
                        helperText={
                          value.organizers.length && value.eventByDisplay
                            ? `${value.eventByDisplay} is the primary organizer`
                            : ""
                        }
                      />
                    )}
                    groupBy={(option: IOrganizer) => option.type}
                    renderTags={(
                      value: IOrganizer[],
                      getTagProps,
                      ownerState
                    ) =>
                      value.map((option: IOrganizer, index) => (
                        <OrganizerChip
                          organizer={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    isOptionEqualToValue={(
                      option: IOrganizer,
                      value: IOrganizer
                    ) => option.by.id === value.by.id}
                    getOptionLabel={(option: IOrganizer) => {
                      if (option.type === EventByType.EventManager)
                        return `${
                          (option.by as IEventManager).nameOfPersonOfContact
                        }, ${(option.by as IEventManager).nameOfCompany}`;
                      return `${(option.by as IEstablishment).name}, ${
                        ((option.by as IEstablishment).area as IArea).name
                      }, ${
                        (
                          ((option.by as IEstablishment).area as IArea)
                            .city as ICity
                        ).name
                      }`;
                    }}
                    getOptionDisabled={(option: IOrganizer) =>
                      !option.by.isActive ||
                      selectedEstablishmentOption?.id === option.by.id ||
                      selectedEventManagerOption?.id === option.by.id
                    }
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
