import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { FailureResponse, Loading, SuccessResponse } from "../common";
import Button from "@mui/material/Button";
import { APIResponse, CreateCategory } from "../../api";
import { getCurrentUser } from "../../services";
import { PATHS } from "../../Constants";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

export const AddCategory = () => {
  const [apiResponse, setAPIResponse] = useState<APIResponse>({
    status: null,
    data: null,
    error: null,
  });

  return apiResponse.status === "success" ? (
    <SuccessResponse
      message="Category Added Successfully"
      id={apiResponse.data.id}
      viewAllPath={PATHS.CATEGORY_LIST}
    />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Add Category
        </Typography>
        <Formik
          initialValues={{
            name: "",
            description: "",
            isActive: true,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = await CreateCategory(values, await getCurrentUser());
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "success",
                data,
              }));
            } catch (error) {
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "failure",
                error,
              }));
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            setFieldTouched,
            values,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <TextField
                        id="cuisine-add-name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        label="Name"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        required={true}
                        onFocus={() => setFieldTouched("name")}
                      />
                    </div>

                    <div>
                      <TextField
                        multiline
                        id="cuisine-add-description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        label="Description"
                        error={touched.description && !!errors.description}
                        helperText={touched.description && errors.description}
                        required={true}
                        onFocus={() => setFieldTouched("description")}
                      />
                    </div>

                    <div>
                      <FormControl sx={{ ml: -1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="cuisine-add-isActive"
                              name="isActive"
                              checked={values.isActive}
                              value={values.isActive}
                              onChange={handleChange}
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                          name="isActive"
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loading isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Box>
      {apiResponse.status === "failure" ? (
        <FailureResponse error={apiResponse.error} />
      ) : null}
    </>
  );
};
