import { AzureBlobName, FilePath } from "@skuare/common";

export const BASE_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "";
export enum PATHS {
  ARTIST_LIST = "/artist/all/:page",
  ARTIST_VIEW = "/artist/:id",
  ARTIST_ADD = "/artist/add",
  ARTIST_EDIT = "/artist/:id/edit",

  AREA_LIST = "/area/all/:page",
  AREA_VIEW = "/area/:id",
  AREA_ADD = "/area/add",
  AREA_EDIT = "/area/:id/edit",

  CATEGORY_LIST = "/category/all/:page",
  CATEGORY_VIEW = "/category/:id",
  CATEGORY_ADD = "/category/add",
  CATEGORY_EDIT = "/category/:id/edit",

  CITY_LIST = "/city/all/:page",
  CITY_VIEW = "/city/:id",
  CITY_ADD = "/city/add",
  CITY_EDIT = "/city/:id/edit",

  CUISINE_LIST = "/cuisine/all/:page",
  CUISINE_VIEW = "/cuisine/:id",
  CUISINE_ADD = "/cuisine/add",
  CUISINE_EDIT = "/cuisine/:id/edit",

  ESTABLISHMENT_LIST = "/establishments/all/:page",
  ESTABLISHMENT_VIEW = "/establishments/:id",
  ESTABLISHMENT_ADD = "/establishments/add",
  ESTABLISHMENT_EDIT = "/establishments/:id/edit",

  EVENT_LIST = "/events/all/:page",
  EVENT_VIEW = "/events/:id",
  EVENT_ADD = "/events/add",
  EVENT_EDIT = "/events/:id/edit",

  EVENT_MANAGER_LIST = "/eventmanagers",
  EVENT_MANAGER_VIEW = "/eventmanagers/:id",
  EVENT_MANAGER_ADD = "/eventmanagers/add",
  EVENT_MANAGER_EDIT = "/eventmanagers/:id/edit",

  EVENTSPACE_LIST = "/eventspaces",
  EVENTSPACE_VIEW = "/eventspaces/:id",
  EVENTSPACE_ADD = "/eventspaces/add",
  EVENTSPACE_EDIT = "/eventspaces/:id/edit",

  GATE_KEEPER_LIST = "/gatekeepers/all/:page",
  GATE_KEEPER_VIEW = "/gatekeepers/:id",
  GATE_KEEPER_ADD = "/gatekeepers/add",
  GATE_KEEPER_EDIT = "/gatekeepers/:id/edit",

  MESSAGE_LIST = "/message",
  MESSAGE_VIEW = "/message/:id",

  OFFER_LIST = "/offers",
  OFFER_VIEW = "/offers/:id",
  OFFER_ADD = "/offers/add",
  OFFER_EDIT = "/offers/:id/edit",

  ORDER_LIST = "/orders",
  ORDER_VIEW = "/orders/:id",

  HOME = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  SIGNUP = "/signup",
  ABOUT = "/about",
  CONTACT = "/contact",
  PRIVACY = "/privacy",
  TNC = "/terms",
  GET_SKUARE = "/GetSkuare",
}

export enum URLS {
  ADMIN_SIGNUP = "/api/admin/signup",
  ADMIN_LOGIN = "/api/admin/login",

  ARTIST_LIST = "/api/artist",
  ARTIST_ADD = ARTIST_LIST,
  ARTIST_DETAIL = "/api/artist/:id",

  AREA_LIST = "/api/area",
  AREA_ADD = AREA_LIST,
  AREA_DETAIL = "/api/area/:id",

  CATEGORY_LIST = "/api/category",
  CATEGORY_ADD = CATEGORY_LIST,
  CATEGORY_DETAIL = "/api/category/:id",

  CITY_LIST = "/api/city",
  CITY_ADD = CITY_LIST,
  CITY_DETAIL = "/api/city/:id",

  CUISINE_LIST = "/api/cuisine",
  CUISINE_ADD = CUISINE_LIST,
  CUISINE_DETAIL = "/api/cuisine/:id",

  ESTABLISHMENT_LIST = "/api/establishment",
  ESTABLISHMENT_ADD = ESTABLISHMENT_LIST,
  ESTABLISHMENT_DETAIL = "/api/establishment/:id",
  ESTABLISHMENT_EVENTS = "/api/establishment/:id/events",

  EVENT_LIST = "/api/event",
  EVENT_ADD = EVENT_LIST,
  EVENT_DETAIL = "/api/event/:id",
  EVENT_STATS = "/api/event/:id/stats",

  EVENT_MANAGER_LIST = "/api/eventmanager",
  EVENT_MANAGER_ADD = EVENT_MANAGER_LIST,
  EVENT_MANAGER_DETAIL = "/api/eventmanager/:id",

  EVENTSPACE_LIST = "/api/eventspace",
  EVENTSPACE_ADD = EVENTSPACE_LIST,
  EVENTSPACE_DETAIL = "/api/eventspace/:id",

  GATE_KEEPER_SIGNUP = "/api/gatekeeper/signup",
  GATE_KEEPER_LIST = "/api/gatekeeper",
  GATE_KEEPER_DETAIL = "/api/gatekeeper/:id",

  MESSAGE_LIST = "/api/message",
  MESSAGE_DETAIL = "/api/message/:id",

  OFFER_LIST = "/api/offer",
  OFFER_ADD = OFFER_LIST,
  OFFER_DETAIL = "/api/offer/:id",

  ORDER_LIST = "/api/order",
  ORDER_DETAIL = "/api/order/:id",

  SEARCH = "/api/search",

  VENUE_LIST = "/api/venue",
  VENUE_ADD = VENUE_LIST,
  VENUE_DETAIL = "/api/venue/:id",
}

export const Token = "token";

export const API_BASE = `${BASE_URL}/api`;

export const Rupee = "₹";

export const platformIconUrl = (platform: string) =>
  `${process.env.REACT_APP_CDN_URL}/${AzureBlobName.Public}/${FilePath.Root}/${FilePath.Logo}/${platform}.png`;

export enum SocialPlatform {
  AppleMusic = "AppleMusic",
  Facebook = "Facebook",
  JioSaavn = "JioSaavn",
  Instagram = "Instagram",
  PrimeMusic = "PrimeMusic",
  Snapchat = "Snapchat",
  SoundCloud = "SoundCloud",
  Spotify = "Spotify",
  Youtube = "Youtube",
  NA = "NA",
}

export const SocialPlatformDisplay = {
  [SocialPlatform.AppleMusic]: "Apple Music",
  [SocialPlatform.Facebook]: "Facebook",
  [SocialPlatform.JioSaavn]: "Jio Saavn",
  [SocialPlatform.Instagram]: "Instagram",
  [SocialPlatform.PrimeMusic]: "Prime Music",
  [SocialPlatform.Snapchat]: "Snapchat",
  [SocialPlatform.SoundCloud]: "Sound Cloud",
  [SocialPlatform.Spotify]: "Spotify",
  [SocialPlatform.Youtube]: "Youtube",
  [SocialPlatform.NA]: "NA",
};

export const HostnameSocialMap: { [key: string]: SocialPlatform } = {
  "music.apple.com": SocialPlatform.AppleMusic,
  "www.music.apple.com": SocialPlatform.AppleMusic,
  "facebook.com": SocialPlatform.Facebook,
  "www.facebook.com": SocialPlatform.Facebook,
  "fb.com": SocialPlatform.Facebook,
  "www.fb.com": SocialPlatform.Facebook,
  "jiosaavn.com": SocialPlatform.JioSaavn,
  "www.jiosaavn.com": SocialPlatform.JioSaavn,
  "instagram.com": SocialPlatform.Instagram,
  "www.instagram.com": SocialPlatform.Instagram,
  "amazonmusic.com": SocialPlatform.PrimeMusic,
  "www.amazonmusic.com": SocialPlatform.PrimeMusic,
  "snapchat.com": SocialPlatform.Snapchat,
  "www.snapchat.com": SocialPlatform.Snapchat,
  "soundcloud.com": SocialPlatform.SoundCloud,
  "www.soundcloud.com": SocialPlatform.SoundCloud,
  "spotify.com": SocialPlatform.Spotify,
  "www.spotify.com": SocialPlatform.Spotify,
  "open.spotify.com": SocialPlatform.Spotify,
  "www.open.spotify.com": SocialPlatform.Spotify,
  "music.youtube.com": SocialPlatform.Youtube,
  "www.music.youtube.com": SocialPlatform.Youtube,
  "youtube.com": SocialPlatform.Youtube,
  "www.youtube.com": SocialPlatform.Youtube,
};
