import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FailureResponse,
  ImageUploadField,
  Loading,
  SuccessResponse,
} from "../common";
import {
  APIResponse,
  CreateOffer,
  GetEstablishments,
  GetEventmanagers,
} from "../../api";
import { getCurrentUser } from "../../services";
import {
  EventByType,
  IArea,
  ICity,
  IEstablishment,
  IEventManager,
  IOrganizer,
} from "@skuare/common";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PATHS } from "../../Constants";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Required"),
  imageUrl: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  organizer: Yup.string().required("Required"),
  organizerType: Yup.string().required("Required"),
  startDateTime: Yup.date().required("Required"),
  endDateTime: Yup.date().required("Required"),
  quantity: Yup.number()
    .min(-1, "Quantity cannot be less than -1")
    .required("Required"),
  maxRedeem: Yup.number()
    .min(1, "Max Redeem should be at least 1")
    .required("Required"),
  tnc: Yup.string().required("Required"),
});

export const AddOffer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [apiResponse, setAPIResponse] = useState<APIResponse>({
    status: null,
    data: null,
    error: null,
  });
  const [establishments, setEstablishments] = useState<IEstablishment[]>([]);
  const [eventManagers, setEventManagers] = useState<IEventManager[]>([]);
  const [selectedOrganizerOption, setSelectedOrganizerOption] =
    useState<IOrganizer>();

  useEffect(() => {
    const getData = async () => {
      const establishmentData = await GetEstablishments(
        { limit: 1000 },
        getCurrentUser()
      );

      const eventManagerData = await GetEventmanagers(
        { limit: 1000 },
        getCurrentUser()
      );

      setEstablishments(establishmentData.results);
      setEventManagers(eventManagerData.results);
      setIsLoading(false);
    };

    getData();
  }, []);

  return apiResponse.status === "success" ? (
    <SuccessResponse
      message="Offer Added Successfully"
      id={apiResponse.data.id}
      shareUrl={apiResponse.data.shareUrl}
      viewAllPath={PATHS.OFFER_LIST}
    />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Add Offer
        </Typography>
        <Formik
          initialValues={{
            title: "",
            imageUrl: "",
            description: "",
            organizer: "",
            organizerType: undefined,
            startDateTime: undefined,
            endDateTime: undefined,
            quantity: -1,
            maxRedeem: 1,
            tnc: "",
            isActive: true,
            city: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = await CreateOffer(values, await getCurrentUser());
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "success",
                data,
              }));
            } catch (error) {
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "failure",
                error,
              }));
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <TextField
                        id="offer-add-title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        label="Title"
                        error={touched.title && !!errors.title}
                        helperText={touched.title && errors.title}
                        required={true}
                        onFocus={() => setFieldTouched("title")}
                      />
                    </div>

                    <div>
                      <Field
                        component={ImageUploadField}
                        id="offer-add-imageUrl"
                        name="imageUrl"
                        label="Offer Image"
                        value={values.imageUrl}
                        onChange={async (value: string) => {
                          setFieldTouched("imageUrl");
                          await setFieldValue("imageUrl", value);
                        }}
                        multi={false}
                        accepts={["image/*"]}
                        error={touched.imageUrl && !!errors.imageUrl}
                      />
                    </div>
                  </Box>
                </Grid>

                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <Field
                        component={Autocomplete}
                        loading={isLoading}
                        style={{ width: "100%" }}
                        disablePortal
                        id="offer-add-organizer"
                        options={[
                          ...eventManagers.map((_) => ({
                            type: EventByType.EventManager,
                            by: _,
                          })),
                          ...establishments.map((_) => ({
                            type: EventByType.Establishment,
                            by: _,
                          })),
                        ]}
                        value={selectedOrganizerOption}
                        onChange={async (event: any, option: IOrganizer) => {
                          setFieldTouched("organizer");
                          if (option) {
                            setSelectedOrganizerOption(option);
                            await setFieldValue(
                              "organizer",
                              option.by.id,
                              true
                            );
                            await setFieldValue("organizerType", option.type);
                            await setFieldValue(
                              "city",
                              option.type === EventByType.Establishment
                                ? (
                                    (
                                      (option.by as IEstablishment)
                                        .area as IArea
                                    ).city as ICity
                                  ).id
                                : (option.by as IEventManager).city
                            );
                          } else {
                            await setFieldValue("organizer", "");
                            await setFieldValue("organizerType", undefined);
                            await setFieldValue("city", "");
                          }
                        }}
                        groupBy={(option: IOrganizer) => option.type}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            {...params}
                            name="organizer"
                            label="Organizer"
                            helperText={touched.organizer && errors.organizer}
                            error={touched.organizer && !!errors.organizer}
                            onFocus={() => setFieldTouched("organizer")}
                            required={true}
                          />
                        )}
                        isOptionEqualToValue={(
                          option: IOrganizer,
                          value: IOrganizer
                        ) => option.by.id === value.by.id}
                        getOptionLabel={(option: IOrganizer) => {
                          if (option.type === EventByType.EventManager)
                            return `${
                              (option.by as IEventManager).nameOfPersonOfContact
                            }, ${(option.by as IEventManager).nameOfCompany}`;
                          return `${(option.by as IEstablishment).name}, ${
                            ((option.by as IEstablishment).area as IArea).name
                          }, ${
                            (
                              ((option.by as IEstablishment).area as IArea)
                                .city as ICity
                            ).name
                          }`;
                        }}
                        getOptionDisabled={(option: IOrganizer) =>
                          !option.by.isActive
                        }
                      />
                    </div>

                    <Stack direction="row" spacing={1}>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="offer-add-quantity"
                            name="quantity"
                            type="number"
                            value={values.quantity}
                            onChange={handleChange}
                            label="Quantity"
                            error={touched.quantity && !!errors.quantity}
                            helperText={
                              (touched.quantity && errors.quantity) ||
                              "Set -1 for Unlimited"
                            }
                            required={true}
                            onFocus={() => setFieldTouched("quantity")}
                          />
                        </div>
                      </Grid>

                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="offer-add-maxRedeem"
                            name="maxRedeem"
                            type="number"
                            value={values.maxRedeem}
                            onChange={handleChange}
                            label="Max Redeem"
                            error={touched.maxRedeem && !!errors.maxRedeem}
                            helperText={touched.maxRedeem && errors.maxRedeem}
                            required={true}
                            onFocus={() => setFieldTouched("maxRedeem")}
                          />
                        </div>
                      </Grid>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <Grid item md={6}>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Start Date Time"
                              value={values.startDateTime}
                              onChange={async (value) => {
                                setFieldTouched("startDateTime");
                                await setFieldValue("startDateTime", value);
                              }}
                              onOpen={() => setFieldTouched("startDateTime")}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>

                      <Grid item md={6}>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="End Date Time"
                              value={values.endDateTime}
                              onChange={async (value) => {
                                setFieldTouched("endDateTime");
                                await setFieldValue("endDateTime", value);
                              }}
                              onOpen={() => setFieldTouched("endDateTime")}
                              minDateTime={values.startDateTime}
                            />
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Stack>

                    <div>
                      <TextField
                        multiline
                        rows={3}
                        id="offer-add-description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        label="Description"
                        required={true}
                        error={touched.description && !!errors.description}
                        helperText={touched.description && errors.description}
                        onFocus={() => setFieldTouched("description")}
                      />
                    </div>

                    <div>
                      <TextField
                        multiline
                        rows={3}
                        id="offer-add-tnc"
                        name="tnc"
                        value={values.tnc}
                        onChange={handleChange}
                        label="Terms"
                        required={true}
                        error={touched.tnc && !!errors.tnc}
                        helperText={touched.tnc && errors.tnc}
                        onFocus={() => setFieldTouched("tnc")}
                      />
                    </div>

                    <div>
                      <FormControl sx={{ ml: -1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="offer-add-isActive"
                              name="isActive"
                              checked={values.isActive}
                              value={values.isActive}
                              onChange={handleChange}
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                          name="isActive"
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loading isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Box>
      {apiResponse.status === "failure" ? (
        <FailureResponse error={apiResponse.error} />
      ) : null}
    </>
  );
};
