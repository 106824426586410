import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FC } from "react";
import { IArtist } from "@skuare/common";

interface ArtistListItemProps {
  artist: IArtist;
  props: any;
}
export const ArtistListItem: FC<ArtistListItemProps> = ({ artist, props }) => {
  return (
    <ListItem alignItems="flex-start" {...props}>
      <ListItemAvatar>
        <Avatar
          alt={artist.stageName}
          src={artist.imageUrl}
          className={artist.isVerified ? "artist-list-item-verified" : ""}
        />
      </ListItemAvatar>
      <ListItemText
        primary={artist.stageName}
        secondary={
          <>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {artist.fullName}
            </Typography>
            &nbsp;- {artist.artistType}
          </>
        }
      />
    </ListItem>
  );
};
