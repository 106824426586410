import React, { FC, useEffect, useState } from "react";
import { SocialLinksField } from "../common";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { GetArtists, GetCategories, GetCuisines } from "../../api";
import { getCurrentUser } from "../../services";
import { IArtist, ICategory, ICuisine } from "@skuare/common";
import { ArtistChip, ArtistListItem } from "../Artist";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Formik, Form } from "formik";
import dayjs from "dayjs";

interface EditEventStep3Error {
  title: string;
  startDateTime: string;
  endDateTime: string;
}

interface EditEventStep3Props {
  value: any;
  handleChange: (event: any) => void;
  onChange: (event: any, value: any, name: string) => void;
  selectedArtists: any;
  setSelectedArtists: (value: any) => void;
  selectedCategories: any;
  setSelectedCategories: (value: any) => void;
  selectedCuisines: any;
  setSelectedCuisines: (value: any) => void;
  setInValidStep: (value: (prevState: Set<number>) => Set<number>) => void;
}
export const EditEventStep3: FC<EditEventStep3Props> = ({
  value,
  handleChange,
  onChange,
  selectedArtists,
  setSelectedArtists,
  selectedCategories,
  setSelectedCategories,
  selectedCuisines,
  setSelectedCuisines,
  setInValidStep,
}) => {
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [cuisines, setCuisines] = useState<ICuisine[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      const artistData = await GetArtists({ limit: 1000 }, getCurrentUser());
      const cuisineData = await GetCuisines({ limit: 1000 }, getCurrentUser());
      const categoryData = await GetCategories(
        { limit: 1000 },
        getCurrentUser()
      );

      setArtists(artistData.results);
      setCuisines(cuisineData.results);
      setCategories(categoryData.results);
      setIsLoading(false);
    };

    getData();
  }, []);

  return (
    <Formik
      initialValues={value}
      onSubmit={() => {}}
      validate={(rawValues) => {
        const errors: Partial<EditEventStep3Error> = {};
        if (!rawValues.title) {
          errors.title = "Required";
        }

        if (!rawValues.startDateTime) {
          errors.startDateTime = "Required";
        }

        if (Object.keys(errors).length > 0) {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState, 2]);
          });
        } else {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState].filter((v) => v !== 2));
          });
        }

        console.log("Errors with", errors, Object.keys(errors).length);

        return errors;
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item md={4}>
              <h2>Event Details</h2>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <div>
                  <TextField
                    id="event-add-title"
                    name="title"
                    value={value.title}
                    onChange={async (event: any) => {
                      handleChange(event);
                      setFieldTouched("title");
                      await setFieldValue("title", event.target.value);
                    }}
                    label="Title"
                    error={touched.title && !!errors.title}
                    onFocus={() => setFieldTouched("title")}
                    required={true}
                  />
                </div>

                <div>
                  <TextField
                    multiline
                    id="event-add-description"
                    name="description"
                    value={value.description}
                    onChange={handleChange}
                    label="Description"
                  />
                </div>

                <Stack direction="row" spacing={1}>
                  <Grid item md={6}>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Start Date Time"
                          value={dayjs(value.startDateTime)}
                          onChange={async (value) => {
                            setFieldTouched("startDateTime");
                            await setFieldValue("startDateTime", value);
                            onChange(undefined, value, "startDateTime");
                          }}
                          onOpen={() => setFieldTouched("startDateTime")}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>

                  <Grid item md={6}>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="End Date Time"
                          value={dayjs(value.endDateTime)}
                          onChange={async (value) => {
                            setFieldTouched("endDateTime");
                            await setFieldValue("endDateTime", value);
                            onChange(undefined, value, "endDateTime");
                          }}
                          onOpen={() => setFieldTouched("endDateTime")}
                          minDateTime={value.startDateTime}
                        />
                      </LocalizationProvider>
                    </div>
                  </Grid>
                </Stack>

                <div>
                  <TextField
                    multiline
                    rows={3}
                    id="event-add-tnc"
                    name="tnc"
                    value={value.tnc}
                    onChange={handleChange}
                    label="Terms"
                    error={touched.tnc && !!errors.tnc}
                    // helperText={touched.tnc && errors.tnc}
                    onFocus={() => setFieldTouched("tnc")}
                  />
                </div>

                <div>
                  <FormGroup>
                    <Stack direction="row" spacing={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="parking"
                            defaultChecked={value.parking}
                            value={value.parking}
                            onChange={handleChange}
                          />
                        }
                        label="Parking"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="valet"
                            defaultChecked={value.valet}
                            value={value.valet}
                            onChange={handleChange}
                          />
                        }
                        label="Valet"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="liquor"
                            defaultChecked={value.liquor}
                            value={value.liquor}
                            onChange={handleChange}
                          />
                        }
                        label="Liquor"
                      />
                    </Stack>
                  </FormGroup>
                </div>
              </Box>
            </Grid>

            <Grid item md={4}>
              <h2>&nbsp;</h2>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <div>
                  <Autocomplete
                    loading={isLoading}
                    style={{ width: "100%" }}
                    disablePortal
                    multiple
                    id="event-add-artists"
                    options={artists}
                    sx={{ width: 300 }}
                    value={selectedArtists}
                    onChange={(event, options) => {
                      if (options) {
                        setSelectedArtists(options);
                        onChange(
                          event,
                          options.map((_: any) => _.id),
                          "artists"
                        );
                      }
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <ArtistChip
                          artist={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderOption={(props, option, state) => (
                      <ArtistListItem artist={option} props={props} />
                    )}
                    renderInput={(params) => (
                      <TextField {...params} name="artists" label="Artists" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      `${option.stageName} (${option.artistType})`
                    }
                    getOptionDisabled={(option) => !option.isActive}
                  />
                </div>

                <div>
                  <SocialLinksField
                    id="event-add-social-links"
                    value={value.socialLinks}
                    onChange={onChange}
                  />
                </div>

                <div>
                  <Autocomplete
                    loading={isLoading}
                    style={{ width: "100%" }}
                    disablePortal
                    multiple
                    id="event-add-category"
                    options={categories}
                    sx={{ width: 300 }}
                    value={selectedCategories}
                    onChange={(event, options) => {
                      if (options) {
                        setSelectedCategories(options);
                        onChange(
                          event,
                          options.map((_: any) => _.id),
                          "categories"
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="categories"
                        label="Categories"
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) => !option.isActive}
                  />
                </div>

                <div>
                  <Autocomplete
                    loading={isLoading}
                    style={{ width: "100%" }}
                    disablePortal
                    multiple
                    id="event-add-cuisine"
                    options={cuisines}
                    sx={{ width: 300 }}
                    value={selectedCuisines}
                    onChange={(event, options) => {
                      if (options) {
                        setSelectedCuisines(options);
                        onChange(
                          event,
                          options.map((_: any) => _.id),
                          "cuisines"
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} name="cuisines" label="Cuisines" />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) => !option.isActive}
                  />
                </div>

                <div>
                  <TextField
                    id="event-add-gmapUrl"
                    name="gmapUrl"
                    value={value.gmapUrl}
                    onChange={handleChange}
                    label="Google Map Url"
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
