import React, { FC, useRef, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

interface ImageUploadFieldProps {
  id: string;
  name: string;
  label: string;
  onChange: (value: any) => void;
  value: any;
  error?: boolean;
}
export const ImageUploadField: FC<ImageUploadFieldProps> = ({
  id,
  name,
  label,
  onChange,
  value,
  error = false,
}) => {
  const [multimedia, setMultimedia] = useState<string>(value);

  const inputFile = useRef<HTMLInputElement | null>(null);
  const openFileExplorer = () => inputFile.current?.click();

  const fileToBase64 = (file: File) => {
    return new Promise(function (resolve, reject) {
      const reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    let data = "";
    const fileList: File[] = Array.from(event.target.files);
    if (fileList.length) {
      data = (await fileToBase64(fileList[0])) as string;
    }
    setMultimedia(data);
    onChange(data);
  };

  const handleDragEnter = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const handleDragLeave = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };
  const handleDragOver = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };
  const handleDrop = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    let data = "";
    const fileList: File[] = Array.from([...event.dataTransfer.files]);
    if (fileList.length) {
      data = (await fileToBase64(fileList[0])) as string;
    }
    setMultimedia(data);
    onChange(data);
  };

  return (
    <>
      <input
        id={id}
        name={name}
        type="file"
        style={{
          display: "none",
        }}
        ref={inputFile}
        onClick={openFileExplorer}
        onChange={handleFileChange}
        accept="image/*"
      />
      {multimedia?.length ? (
        <Tooltip title={label}>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1 / 1",
              border: error ? "1px solid red" : "0.5px solid lightgray",
              borderRadius: "4px",
              margin: "8px",
              backgroundImage: multimedia,
              display: "flex",
              textAlign: "center",
            }}
            onClick={openFileExplorer}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <img
              src={multimedia}
              style={{
                display: "flex",
                height: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                margin: "auto",
                objectFit: "cover",
                objectPosition: "center",
              }}
              alt={name}
            />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={label}>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1 / 1",
              border: error ? "1px solid red" : "0.5px dashed lightgray",
              borderRadius: "4px",
              margin: "8px",
            }}
            onClick={openFileExplorer}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <Box
              sx={{
                top: "calc(50% - 17.5px)",
                left: "calc(50% - 17.5px)",
                position: "relative",
              }}
            >
              <PhotoCameraIcon fontSize="large" />
            </Box>
          </Box>
        </Tooltip>
      )}
    </>
  );
};
