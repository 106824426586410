import axios from "axios";
import { IArtist, IPaginatedResponse } from "@skuare/common";
import { URLS } from "../Constants";

import { GetApiUrl } from "../utils";
import { User } from "firebase/auth";

export const GetArtists = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<IArtist>>(
      GetApiUrl(URLS.ARTIST_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const CreateArtist = async (
  artist: Partial<IArtist>,
  user: User | null
) => {
  try {
    const { data } = await axios.post<Partial<IArtist>>(
      GetApiUrl(URLS.ARTIST_ADD),
      artist,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};

export const GetArtist = async (id: string, user: User | null) => {
  try {
    const { data } = await axios.get<Partial<IArtist>>(
      GetApiUrl(URLS.ARTIST_DETAIL, { id }),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};

export const UpdateArtist = async (
  id: string,
  artist: Partial<IArtist>,
  user: User | null
) => {
  try {
    const { data } = await axios.put<Partial<IArtist>>(
      GetApiUrl(URLS.ARTIST_DETAIL, { id }),
      artist,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
