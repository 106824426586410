import React, { useEffect, useState } from "react";
import { GetEvents } from "../../api";
import { IAggregatedEvent, IPaginatedResponse } from "@skuare/common";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Skeleton,
  Stack,
  TablePagination,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCurrentUser } from "../../services";
import { EventListCard } from "./EventListCard";
import { EventStatCard } from "./EventStatCard";
import { PATHS } from "../../Constants";

export const EventList = () => {
  const { page = "1" } = useParams();
  useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [, setIsDataLoading] = useState(true);
  const [pageData, setPageData] =
    useState<IPaginatedResponse<IAggregatedEvent>>();
  const [paginationModel, setPaginationModel] = useState({
    page: parseInt(page) - 1,
    eventsPerPage: 10,
  });

  const [events, setEvents] = useState<IAggregatedEvent[]>(
    new Array<IAggregatedEvent>()
  );

  const [selectedEvent, setSelectedEvent] = useState<IAggregatedEvent>();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => setPaginationModel((prevState) => ({ ...prevState, page }));

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setPaginationModel({
      page: 0,
      eventsPerPage: parseInt(event.target.value, 10),
    });

  useEffect(() => {
    const getData = async () => {
      setIsDataLoading(true);
      const data = await GetEvents(
        {
          skip: paginationModel.eventsPerPage * paginationModel.page,
          limit: paginationModel.eventsPerPage,
        },
        getCurrentUser()
      );
      setPageData(data);
      setIsPageLoading(false);
      setIsDataLoading(false);
      setEvents(data.results);
      setSelectedEvent(data.results[0]);
    };
    getData();
  }, [paginationModel.page, paginationModel.eventsPerPage]);
  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h1>Event</h1>
        <Link to={PATHS.EVENT_ADD}>
          <Button variant="contained">Add Event</Button>
        </Link>
      </Stack>
      <Grid container>
        <Grid item md={4}>
          <List className="event-list-container">
            {isPageLoading
              ? [...Array(5)].map((_) => (
                  <ListItem>
                    <Skeleton variant="rectangular" width="100%" height={120} />
                  </ListItem>
                ))
              : events.map((event: IAggregatedEvent) => (
                  <ListItem
                    onClick={() => setSelectedEvent(event)}
                    key={event.id}
                  >
                    <EventListCard
                      event={event}
                      highlight={event.id === selectedEvent?.id}
                    />
                  </ListItem>
                ))}
          </List>
          <TablePagination
            component="div"
            sx={{ display: "flex", justifyContent: "center" }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            count={pageData?.count || 0}
            page={paginationModel.page}
            onPageChange={handleChangePage}
            rowsPerPage={paginationModel.eventsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Grid>
        <Grid item md={8} className="event-stat-card">
          {isPageLoading || selectedEvent === undefined ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <EventStatCard eventId={selectedEvent.id} setEvents={setEvents} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
