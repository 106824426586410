import { EventByType, EventType, OrderStatus } from "@skuare/common";

export const EventTypePalette = {
  [EventType.BrunchEvent]: "#B8B8D1",
  [EventType.ChristmasEvent]: "#DD4B1A",
  [EventType.ExhibitionEvent]: "#5B5F97",
  [EventType.HalloweenEvent]: "#FF5714",
  [EventType.HoliEvent]: "#FFC145",
  [EventType.LiveBandEvent]: "#9AE19D",
  [EventType.LiveScreeningEvent]: "#FF6B6C",
  [EventType.LoungeEvent]: "#C2D076",
  [EventType.NavratriEvent]: "#DB3069",
  [EventType.NewYearEvent]: "#A833B9",
  [EventType.OneTimeEvent]: "#47682C",
  [EventType.SportsEvent]: "#FFA0FD",
  [EventType.StandUpEvent]: "#E952DE",
};

export const EventByTypePalette = {
  [EventByType.Establishment]: "#F17105",
  [EventByType.EventManager]: "#1A8FE3",
};

export const OrderStatusPalette = {
  [OrderStatus.Open]: "#FD5200",
  [OrderStatus.Paid]: "#0CCA4A",
  [OrderStatus.Completed]: "#2D1E2F",
  [OrderStatus.Expired]: "#B0B5B3",
  [OrderStatus.PaymentFailed]: "#C42021",
};
