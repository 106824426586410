import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FailureResponse, Loading, SuccessResponse } from "../common";
import Button from "@mui/material/Button";
import { APIResponse, CreateArea, GetCities } from "../../api";
import { getCurrentUser } from "../../services";
import { PATHS } from "../../Constants";
import { ICity } from "@skuare/common";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  pinCode: Yup.number().required("Required"),
  city: Yup.string().required("Required"),
  location: Yup.object().shape({
    latitude: Yup.number().required("Required"),
    longitude: Yup.number().required("Required"),
  }),
});

export const AddArea = () => {
  const [cities, setCities] = useState<ICity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCity, setSelectedCity] = useState<ICity>();
  const [apiResponse, setAPIResponse] = useState<APIResponse>({
    status: null,
    data: null,
    error: null,
  });

  useEffect(() => {
    const getData = async () => {
      const data = await GetCities(undefined, getCurrentUser());
      setCities(data.results);
      setIsLoading(false);
    };

    getData();
  }, []);

  return apiResponse.status === "success" ? (
    <SuccessResponse
      message="Area Added Successfully"
      id={apiResponse.data.id}
      viewAllPath={PATHS.AREA_LIST}
    />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Add Area
        </Typography>
        <Formik
          initialValues={{
            name: "",
            pinCode: 0,
            city: "",
            isActive: true,
            location: {
              latitude: 0,
              longitude: 0,
            },
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = await CreateArea(values, await getCurrentUser());
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "success",
                data,
              }));
            } catch (error) {
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "failure",
                error,
              }));
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <TextField
                        id="area-add-name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        label="Name"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        required={true}
                        onFocus={() => setFieldTouched("name")}
                      />
                    </div>

                    <div>
                      <Field
                        component={Autocomplete}
                        loading={isLoading}
                        style={{ width: "100%" }}
                        disablePortal
                        id="event-add-city"
                        options={cities}
                        value={selectedCity}
                        onChange={async (event: any, option: ICity) => {
                          setFieldTouched("city");
                          if (option) {
                            setSelectedCity(option);
                            await setFieldValue("city", option.id);
                          } else {
                            setSelectedCity(undefined);
                            await setFieldValue("city", "");
                          }
                        }}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            {...params}
                            name="city"
                            label="City"
                            error={touched["city"] && !!errors["city"]}
                            onTouchEnd={() => setFieldTouched("city")}
                            required={true}
                          />
                        )}
                        isOptionEqualToValue={(option: ICity, value: ICity) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option: ICity) => option.name}
                        getOptionDisabled={(option: ICity) => !option.isActive}
                      />
                    </div>

                    <div>
                      <TextField
                        id="area-add-pinCode"
                        name="pinCode"
                        value={values.pinCode}
                        onChange={handleChange}
                        label="Pin Code"
                        error={touched.pinCode && !!errors.pinCode}
                        helperText={touched.pinCode && errors.pinCode}
                        required={true}
                        onFocus={() => setFieldTouched("pinCode")}
                      />
                    </div>

                    <Stack direction="row" spacing={1}>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="area-add-latitude"
                            name="location.latitude"
                            type="number"
                            value={values.location.latitude}
                            onChange={handleChange}
                            label="Latitude"
                            error={
                              touched.location?.latitude &&
                              !!errors.location?.latitude
                            }
                            helperText={
                              touched.location?.latitude &&
                              errors.location?.latitude
                            }
                            required={true}
                            onFocus={() => setFieldTouched("location.latitude")}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="area-add-longitude"
                            name="location.longitude"
                            type="number"
                            value={values.location.longitude}
                            onChange={handleChange}
                            label="Longitude"
                            error={
                              touched.location?.longitude &&
                              !!errors.location?.longitude
                            }
                            helperText={
                              touched.location?.longitude &&
                              errors.location?.longitude
                            }
                            required={true}
                            onFocus={() =>
                              setFieldTouched("location.longitude")
                            }
                          />
                        </div>
                      </Grid>
                    </Stack>

                    <div>
                      <FormControl sx={{ ml: -1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              id="area-add-isActive"
                              name="isActive"
                              checked={values.isActive}
                              value={values.isActive}
                              onChange={handleChange}
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                          name="isActive"
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loading isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Box>
      {apiResponse.status === "failure" ? (
        <FailureResponse error={apiResponse.error} />
      ) : null}
    </>
  );
};
