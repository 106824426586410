import { URLS } from "../Constants";
import axios from "axios";
import { ICategory, IPaginatedResponse } from "@skuare/common";
import { GetApiUrl } from "../utils";
import { User } from "firebase/auth";

export const GetCategories = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<ICategory>>(
      GetApiUrl(URLS.CATEGORY_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const GetCategory = (categoryId: string) =>
  axios
    .get<ICategory>(GetApiUrl(URLS.CATEGORY_DETAIL, { id: categoryId }))
    .then((resp) => resp.data);

export const CreateCategory = async (
  category: Partial<ICategory>,
  user: User | null
) => {
  try {
    const { data } = await axios.post<Partial<ICategory>>(
      GetApiUrl(URLS.CATEGORY_ADD),
      category,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
