import { GetCities, GetCuisines } from "../../api";
import { ICity, ICuisine } from "@skuare/common";
import { GridColDef } from "@mui/x-data-grid";
import { DataContainer } from "../common";
import { PATHS } from "../../Constants";
import { Avatar } from "@mui/material";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
  },
  {
    field: "isActive",
    headerName: "Active",
    type: "boolean",
    flex: 1,
    editable: true,
  },
  {
    field: "createdAt",
    headerName: "Created",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
];

export const CuisineList = () => (
  <DataContainer<ICuisine>
    header="Cuisine"
    GetData={GetCuisines}
    columns={columns}
    path={PATHS.CUISINE_LIST}
    addNew={PATHS.CUISINE_ADD}
  />
);
