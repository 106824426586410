import axios from "axios";
import {
  IEvent,
  IEventManager,
  IFollower,
  IPageParams,
  IPaginatedResponse,
  ObjectToParams,
  defaultPageParams,
} from "@skuare/common";
import { API_BASE, URLS } from "../Constants";
import { User } from "firebase/auth";
import { GetApiUrl } from "../utils";

export const GetEventmanagers = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<IEventManager>>(
      GetApiUrl(URLS.EVENT_MANAGER_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const GetEventManager = async (
  eventManagerId: string,
  user: User | null
) =>
  axios
    .get<IEventManager>(
      GetApiUrl(URLS.EVENT_MANAGER_DETAIL, { id: eventManagerId }),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const UpdateEventManager = async (
  eventManagerId: string,
  eventManager: Partial<IEventManager>,
  user: User | null
) =>
  axios
    .put<IEventManager>(
      GetApiUrl(URLS.EVENT_MANAGER_DETAIL, { id: eventManagerId }),
      eventManager,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const ToggleFollowEventManager = (
  eventManagerId?: string,
  follow: boolean = true
) =>
  axios
    .get<IFollower>(
      `${API_BASE}/eventmanager/${eventManagerId}/${!follow ? "un" : ""}follow`
    )
    .then((resp) => resp.data);

export const GetEventmanagerEvents = (
  eventManagerId?: string,
  pageParams: IPageParams = defaultPageParams
) =>
  !Boolean(eventManagerId)
    ? Promise.resolve({
        count: 0,
        limit: 10,
        skip: 0,
        results: [],
      })
    : axios
        .get<IPaginatedResponse<IEvent>>(
          `${API_BASE}/eventmanager/${eventManagerId}/events${ObjectToParams({
            ...defaultPageParams,
            ...pageParams,
          })}`
        )
        .then((resp) => resp.data);

export const CreateEventManager = async (
  eventManager: Partial<IEventManager>,
  user: User | null
) => {
  try {
    const { data } = await axios.post<Partial<IEventManager>>(
      GetApiUrl(URLS.EVENT_MANAGER_ADD),
      eventManager,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
