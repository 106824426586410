import axios from "axios";
import { IAggregatedEvent, IPaginatedResponse } from "@skuare/common";
import { URLS } from "../Constants";
import { User } from "firebase/auth";
import { GetApiUrl } from "../utils";

export const GetEvents = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<IAggregatedEvent>>(
      GetApiUrl(URLS.EVENT_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const GetEvent = async (eventId: string, user: User | null) =>
  axios
    .get<IAggregatedEvent>(
      GetApiUrl(URLS.EVENT_DETAIL, { id: eventId }),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const GetEventStats = async (eventId: string, user: User | null) =>
  axios
    .get(
      GetApiUrl(URLS.EVENT_STATS, { id: eventId }),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const CreateEvent = async (event: any, user: User | null) => {
  try {
    const { data } = await axios.post(
      GetApiUrl(URLS.EVENT_ADD),
      event,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    const { error = {}, errors = {} } = e;
    return { error, errors };
  }
};

export const UpdateEvent = async (
  id: string,
  event: any,
  user: User | null
) => {
  try {
    const { data } = await axios.put(
      GetApiUrl(URLS.EVENT_DETAIL, { id }),
      event,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    const { error = {}, errors = {} } = e;
    return { error, errors };
  }
};

export const PatchEvent = async (
  id: string,
  event: Partial<IAggregatedEvent>,
  user: User | null
) => {
  try {
    const { data } = await axios.patch(
      GetApiUrl(URLS.EVENT_DETAIL, { id }),
      event,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    const { error = {}, errors = {} } = e;
    return { error, errors };
  }
};
