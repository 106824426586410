import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { SnackBar } from "./SnackBar";
import { PATHS } from "../../Constants";

interface SuccessResponseProps {
  message: string;
  viewExternalUrl?: string;
  previewPath?: PATHS;
  id?: string;
  shareUrl?: string;
  viewAllPath?: PATHS | string;
}
export const SuccessResponse: FC<SuccessResponseProps> = ({
  message,
  viewExternalUrl,
  previewPath,
  id,
  shareUrl,
  viewAllPath,
}) => {
  const [open, setOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item md={6} justifyContent="center" alignItems="center">
          <Box sx={{ paddingTop: "50px" }}>
            <Stack direction="row" spacing={2} justifyContent="center">
              <Typography variant="h4" gutterBottom>
                {message}
              </Typography>
              <CheckCircleIcon color="success" fontSize="large" />
            </Stack>
            <Stack direction="row" spacing={4} justifyContent="center">
              {id ? (
                <Tooltip title="Copy ID">
                  <Button
                    endIcon={<ContentCopyIcon />}
                    onClick={() => {
                      setSnackBarMessage("ID Copied to clipboard");
                      setOpen(true);
                      navigator.clipboard.writeText(id);
                    }}
                    variant="outlined"
                    color="info"
                  >
                    {id}
                  </Button>
                </Tooltip>
              ) : null}
              {viewExternalUrl ? (
                <Tooltip title="Open in New Tab">
                  <Link
                    to={viewExternalUrl || ""}
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    <IconButton color="info">
                      <OpenInNewIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              ) : null}
              {previewPath ? (
                <Tooltip title="Preview">
                  <Link to={previewPath || ""}>
                    <IconButton color="info">
                      <VisibilityIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              ) : null}
              {shareUrl ? (
                <Tooltip title="Copy Share URL">
                  <IconButton
                    color="info"
                    onClick={() => {
                      setSnackBarMessage("Share Url Copied to clipboard");
                      setOpen(true);
                      navigator.clipboard.writeText(shareUrl);
                    }}
                  >
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              {viewAllPath ? (
                <Tooltip title="View Complete List">
                  <Link to={viewAllPath || ""}>
                    <Button variant="contained">View All</Button>
                  </Link>
                </Tooltip>
              ) : null}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <SnackBar
        open={open}
        handleClose={() => setOpen(false)}
        message={snackBarMessage}
      />
    </>
  );
};
