import {initializeApp} from "firebase/app"
import {
    GoogleAuthProvider,
    FacebookAuthProvider,
    EmailAuthProvider,
    PhoneAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    getAuth,
    linkWithPopup,
    signInWithPopup,
    User,
    linkWithCredential,
    onAuthStateChanged,
    signInAnonymously,
    signOut,
    updatePassword,
    ConfirmationResult,
} from "firebase/auth";
import {Token} from "../Constants"


const app = initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || ""))

export type IAuthUser = User;

export type ISupportedSocialPlatforms = "facebook" | "google";

export const signupWithFirebase = async (email: string, password: string) => {
    const authentication = getAuth(app)
    try {
        return await createUserWithEmailAndPassword(authentication, email, password)
    } catch (error) {
        console.log(error)
    }
}

export const loginWithFirebase = async (email: string, password: string) => {
    const authentication = getAuth(app)
    try {
        const response = await signInWithEmailAndPassword(authentication, email, password)
        localStorage.setItem(Token, await response.user.getIdToken())
        return response
    } catch (error) {
        console.log(error)
    }
}

export const logoutWithFirebase = async () => {
    const authentication = getAuth(app)
    try {
        await signOut(authentication)
        localStorage.removeItem(Token)
    } catch (error) {
        console.log(error)
    }
}

export const isLoggedIn = async () => {
    // const authentication = getAuth(app)
    // const token = await authentication?.currentUser?.getIdToken(true)
    // console.log(authentication.currentUser, token, token || "")
    // localStorage.setItem(Token, token || "")
    return !!localStorage.getItem(Token)
}

export const getCurrentUser = () => {
    const authentication = getAuth(app)
    return authentication.currentUser
};

export const getAccessToken = () => {
    const authentication = getAuth(app)
    authentication.currentUser?.getIdToken() ?? Promise.resolve("");
}

export const LinkEmailCredential = async (email: string, password: string): Promise<User | null> => {
    const currentUser = getCurrentUser();
    if (currentUser) {
        const emailCredetial = EmailAuthProvider.credential(email, password);
        return linkWithCredential(currentUser, emailCredetial).then((userCred) => userCred.user);
    } else {
        return null;
    }
};

export const LinkPhoneCredential = async (confirmationCode: ConfirmationResult, code: string): Promise<User | null> => {
    const currentUser = getCurrentUser();
    if (currentUser) {
        const phoneCredential = PhoneAuthProvider.credential(confirmationCode.verificationId, code);
        return linkWithCredential(currentUser, phoneCredential).then((userCred) => userCred.user);
    } else {
        return null;
    }
};

export const UpdatePassword = async (oldPassword: string, newPassword: string) => {
    return getCurrentUser() ? updatePassword(getCurrentUser()!, newPassword) : Promise.resolve();
};

export const LoginSocial = async (socialProvider: ISupportedSocialPlatforms) => {
    try {
        const authentication = getAuth(app)
        const currentUser = getCurrentUser();

        switch (socialProvider) {
            case "facebook":
                if (currentUser) {
                    return linkWithPopup(currentUser, new FacebookAuthProvider()).then((userCred) => userCred.user);
                } else {
                    return signInWithPopup(authentication, new FacebookAuthProvider()).then((userCred) => userCred.user);
                }
            default:
            case "google":
                if (currentUser) {
                    return linkWithPopup(currentUser, new GoogleAuthProvider()).then((userCred) => userCred.user);
                } else {
                    return signInWithPopup(authentication, new GoogleAuthProvider()).then((userCred) => userCred.user);
                }
        }
        // Sign-in the user with the credential
    } catch (e) {
        return Promise.reject(e);
    }
};

export const CreateAccount = async (email: string, password: string): Promise<User> => {
    const authentication = getAuth(app)
    return createUserWithEmailAndPassword(authentication, email, password).then((resp) => resp.user);
}

export const SignInViaEmail = async (email: string, password: string): Promise<User> => {
    const authentication = getAuth(app)
    return signInWithEmailAndPassword(authentication, email, password).then((Resp) => Resp.user);
}

export const SignInAnonymously = async () => {
    const authentication = getAuth(app)
    return signInAnonymously(authentication).then((resp) => resp.user);
}

export const SignUpUsingEmail = (email: string, pass: string) => {
    const authentication = getAuth(app)
    return createUserWithEmailAndPassword(authentication, email, pass).then((resp) => resp.user);
}

export const SignOut = async () => {
    const authentication = getAuth(app)
    !!getCurrentUser() ? signOut(authentication) : Promise.resolve();
}

export const authListener = (callback: (user: User | null) => void) => {
    const authentication = getAuth(app)
    return onAuthStateChanged(authentication, callback);
}
