import { FC } from "react";
import {
  EventByType,
  IArea,
  IEstablishment,
  IEventManager,
  IOrganizer,
} from "@skuare/common";
import { Chip } from "@mui/material";
import { EventByTypePalette } from "../../Styles";

export interface OrganizerChipProps {
  organizer: IOrganizer;
}
export const OrganizerChip: FC<OrganizerChipProps> = ({
  organizer,
  ...props
}) => (
  <Chip
    label={
      organizer.type === EventByType.Establishment
        ? `${(organizer.by as IEstablishment).name}, ${
            ((organizer.by as IEstablishment).area as IArea).name
          }`
        : `${(organizer.by as IEventManager).nameOfPersonOfContact}, ${
            (organizer.by as IEventManager).nameOfCompany
          }`
    }
    sx={{
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: EventByTypePalette[organizer.type],
      backgroundColor: "inherit",
    }}
    {...props}
  />
);
