import axios from "axios"
import {BASE_URL, Token} from "../Constants"

export const get = async (url: string, authenticated: boolean = true) => {
    try {
        if (authenticated) {
            const {data} = await axios.get(`${BASE_URL}${url}`, {
                headers: {Authorization: `Bearer ${localStorage.getItem(Token) || ""}`},
            })
            return data
        } else {
            const {data} = await axios.get(`${BASE_URL}${url}`)
            return data
        }
    } catch (error) {
        return error
    }
}

export const post = async (url: string, body: any, authenticated: boolean = true) => {
    try {
        if (authenticated) {
            const {data} = await axios.post(`${BASE_URL}${url}`, body, {
                headers: {Authorization: `Bearer ${localStorage.getItem(Token) || ""}`},
            })
            return data
        } else {
            const {data} = await axios.post(`${BASE_URL}${url}`, body)
            return data
        }
    } catch (error) {
        return error
    }
}

export const put = async (url: string, body: any, authenticated: boolean = true) => {
    try {
        if (authenticated) {
            const {data} = await axios.put(`${BASE_URL}${url}`, body, {
                headers: {Authorization: `Bearer ${localStorage.getItem(Token) || ""}`},
            })
            return data
        } else {
            const {data} = await axios.put(`${BASE_URL}${url}`, body)
            return data
        }
    } catch (error) {
        return error
    }
}