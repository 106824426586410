import { FC } from "react";
import {
  EventType,
  IAggregatedEvent,
  IArea,
  ShowDateRange,
} from "@skuare/common";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { EventTypePalette } from "../../Styles";

interface EventListCardProps {
  event: IAggregatedEvent;
  highlight: boolean;
}

export const EventListCard: FC<EventListCardProps> = ({ event, highlight }) => {
  return (
    <>
      <Typography className="event-list-card-type-label">
        {event.typeDisplay}
      </Typography>
      <Card
        sx={{
          display: "flex",
          width: "100%",
          borderRight: highlight
            ? `2px solid ${
                EventTypePalette[event?.type || EventType.BrunchEvent]
              }`
            : "",
          borderTop: highlight
            ? `2px solid ${
                EventTypePalette[event?.type || EventType.BrunchEvent]
              }`
            : "",
          borderBottom: highlight
            ? `2px solid ${
                EventTypePalette[event?.type || EventType.BrunchEvent]
              }`
            : "",
          textDecorationStyle: "solid",
          textDecorationLine: event.isActive ? "" : "line-through",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            borderLeft: `20px solid ${
              EventTypePalette[event?.type || EventType.BrunchEvent]
            }`,
          }}
        >
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Grid container>
              <Grid item>
                <Typography component="div" variant="h5">
                  {event.title}
                </Typography>
                <Typography variant="h6" color="text.secondary" component="div">
                  {`${event.venue.name}, ${(event.area as IArea).name}`}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  component="div"
                >
                  {ShowDateRange(event.startDateTime, event.endDateTime)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};
