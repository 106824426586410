import { EventType, EventTypeDisplay } from "@skuare/common";
import { FC } from "react";
import { Chip } from "@mui/material";
import { EventTypePalette } from "../../Styles";

interface EventTypeChipProps {
  type: EventType | undefined;
}

export const EventTypeChip: FC<EventTypeChipProps> = ({ type }) => (
  <Chip
    label={type ? EventTypeDisplay[type].toUpperCase() : "Event"}
    sx={{
      backgroundColor: EventTypePalette[type || EventType.BrunchEvent],
      fontWeight: "bold",
    }}
  />
);
