import React, { createContext, useContext, useState, useEffect, FC, PropsWithChildren } from "react";
import { useUserContext } from "./User";
import {
    IAuthUser,
    SignInViaEmail,
    SignOut,
    LoginSocial,
    authListener,
    ISupportedSocialPlatforms,
    SignInAnonymously,
} from "../services";

interface IAuthContext {
    user: IAuthUser | null;
    signInUsingEmail: (email: string, password: string) => Promise<IAuthUser>;
    signInSocial: (type: ISupportedSocialPlatforms) => Promise<IAuthUser>;
    signOut: () => Promise<void>;
    signInAnonymously: () => Promise<IAuthUser>;
}

const defaultContext: IAuthContext = {
    user: null,
    signInUsingEmail: () => Promise.resolve({} as IAuthUser),
    signInSocial: () => Promise.resolve({} as IAuthUser),
    signOut: () => Promise.resolve(),
    signInAnonymously: () => Promise.resolve({} as IAuthUser),
};

const AuthContext = createContext(defaultContext);

export const useAuthContext = () => useContext(AuthContext);

const useAuthProvider = (): IAuthContext => {
    const { setUserInfo, cleanUserInfo } = useUserContext();
    const [user, setUser] = useState<IAuthUser | null>(null);

    useEffect(() => {
        const listener = authListener((authenticatedUser) => {
            console.log("Auth State Change");
            setUserInfo(authenticatedUser);
        });
        return listener;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const signInUsingEmail = (email: string, password: string) => {
        // ShowLoader(true);
        return SignInViaEmail(email, password)
            .then((res) => {
                setUser(res);
                setUserInfo(res);
                return res;
            })
            .finally(() => {
                // ShowLoader(false);
            });
    };

    const signOutUser = () =>
        SignOut().then((Resp) => {
            cleanUserInfo();
            return Resp;
        });

    const signInSocial = (platform: ISupportedSocialPlatforms) => {
        // ShowLoader(true);
        return LoginSocial(platform)
            .then((res) => {
                setUser(res);
                setUserInfo(res);
                return res;
            })
            .finally(() => {
                // ShowLoader(false);
            });
    };

    const signInAnonymously = () => {
        // ShowLoader(true);
        return SignInAnonymously()
            .then((res) => {
                setUser(res);
                setUserInfo(res);
                return res;
            })
            .finally(() => {
                // ShowLoader(false);
            });
    };

    return {
        user,
        signInUsingEmail,
        signInSocial,
        signOut: signOutUser,
        signInAnonymously,
    };
};

export const AuthProvider: FC<PropsWithChildren> = (props) => {
    const providerValue = useAuthProvider();
    return <AuthContext.Provider value={providerValue}>{props.children}</AuthContext.Provider>;
};
