import { URLS } from "../Constants";
import axios from "axios";
import { ICuisine, IPaginatedResponse } from "@skuare/common";
import { GetApiUrl } from "../utils";
import { User } from "firebase/auth";

export const GetCuisines = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<ICuisine>>(
      GetApiUrl(URLS.CUISINE_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const GetCuisine = (cuisineId: string) =>
  axios
    .get<ICuisine>(GetApiUrl(URLS.CUISINE_DETAIL, { id: cuisineId }))
    .then((resp) => resp.data);

export const CreateCuisine = async (
  cuisine: Partial<ICuisine>,
  user: User | null
) => {
  try {
    const { data } = await axios.post<Partial<ICuisine>>(
      GetApiUrl(URLS.CUISINE_ADD),
      cuisine,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
