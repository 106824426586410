import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FailureResponse, Loading, SuccessResponse } from "../common";
import { APIResponse, CreateEventSpace, GetAreas } from "../../api";
import { getCurrentUser } from "../../services";
import { PATHS } from "../../Constants";
import { EventSpaceType, IArea, ICity } from "@skuare/common";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  area: Yup.string().required("Required"),
  locationType: Yup.string().required("Required"),
  location: Yup.object().shape({
    latitude: Yup.number().required("Required"),
    longitude: Yup.number().required("Required"),
  }),
});

export const AddEventSpace = () => {
  const [areas, setAreas] = useState<IArea[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedArea, setSelectedArea] = useState<IArea>();
  const [apiResponse, setAPIResponse] = useState<APIResponse>({
    status: null,
    data: null,
    error: null,
  });

  useEffect(() => {
    const getData = async () => {
      const data = await GetAreas({ limit: 1000 }, getCurrentUser());
      setAreas(data.results);
      setIsLoading(false);
    };

    getData();
  }, []);

  return apiResponse.status === "success" ? (
    <SuccessResponse
      message="Event Space Added Successfully"
      id={apiResponse.data.id}
      viewAllPath={PATHS.EVENTSPACE_LIST}
    />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h4" gutterBottom>
          Add Event Space
        </Typography>
        <Formik
          initialValues={{
            name: "",
            address: "",
            area: "",
            gmapUrl: "",
            locationType: EventSpaceType.BOTH,
            parking: false,
            valet: false,
            isActive: true,
            location: {
              latitude: 0,
              longitude: 0,
            },
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = await CreateEventSpace(
                values,
                await getCurrentUser()
              );
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "success",
                data,
              }));
            } catch (error) {
              setAPIResponse((prevState) => ({
                ...prevState,
                status: "failure",
                error,
              }));
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            handleChange,
            isSubmitting,
            isValid,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Box
                    component="form"
                    sx={{
                      "& .MuiTextField-root": { m: 1, width: "100%" },
                    }}
                  >
                    <div>
                      <TextField
                        id="event-space-add-name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        label="Name"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        required={true}
                        onFocus={() => setFieldTouched("name")}
                      />
                    </div>

                    <div>
                      <Field
                        component={Autocomplete}
                        loading={isLoading}
                        style={{ width: "100%" }}
                        disablePortal
                        id="event-add-area"
                        options={areas}
                        value={selectedArea}
                        onChange={async (event: any, option: IArea) => {
                          setFieldTouched("area");
                          if (option) {
                            setSelectedArea(option);
                            await setFieldValue("area", option.id);
                          } else {
                            setSelectedArea(undefined);
                            await setFieldValue("area", "");
                          }
                        }}
                        renderInput={(
                          params: AutocompleteRenderInputParams
                        ) => (
                          <TextField
                            {...params}
                            name="area"
                            label="Area"
                            error={touched["area"] && !!errors["area"]}
                            onTouchEnd={() => setFieldTouched("area")}
                            required={true}
                          />
                        )}
                        isOptionEqualToValue={(option: IArea, value: IArea) =>
                          option.id === value.id
                        }
                        getOptionLabel={(option: IArea) =>
                          `${option.name}, ${option.pinCode}, ${
                            (option.city as ICity).name
                          }`
                        }
                        getOptionDisabled={(option: IArea) => !option.isActive}
                      />
                    </div>

                    <div>
                      <TextField
                        id="event-space-add-address"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        label="Address"
                        error={touched.address && !!errors.address}
                        helperText={touched.address && errors.address}
                        required={true}
                        onFocus={() => setFieldTouched("address")}
                      />
                    </div>

                    <div>
                      <TextField
                        id="event-space-add-gmapUrl"
                        name="gmapUrl"
                        value={values.gmapUrl}
                        onChange={handleChange}
                        label="Gmap URL"
                        error={touched.gmapUrl && !!errors.gmapUrl}
                        helperText={touched.gmapUrl && errors.gmapUrl}
                        onFocus={() => setFieldTouched("gmapUrl")}
                      />
                    </div>

                    <Stack direction="row" spacing={1}>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="event-space-add-latitude"
                            name="location.latitude"
                            type="number"
                            value={values.location.latitude}
                            onChange={handleChange}
                            label="Latitude"
                            error={
                              touched.location?.latitude &&
                              !!errors.location?.latitude
                            }
                            helperText={
                              touched.location?.latitude &&
                              errors.location?.latitude
                            }
                            required={true}
                            onFocus={() => setFieldTouched("location.latitude")}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6}>
                        <div>
                          <TextField
                            id="event-space-add-longitude"
                            name="location.longitude"
                            type="number"
                            value={values.location.longitude}
                            onChange={handleChange}
                            label="Longitude"
                            error={
                              touched.location?.longitude &&
                              !!errors.location?.longitude
                            }
                            helperText={
                              touched.location?.longitude &&
                              errors.location?.longitude
                            }
                            required={true}
                            onFocus={() =>
                              setFieldTouched("location.longitude")
                            }
                          />
                        </div>
                      </Grid>
                    </Stack>

                    <Stack direction="row" justifyContent="space-between">
                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="event-space-add-parking"
                                name="parking"
                                checked={values.parking}
                                value={values.parking}
                                onChange={handleChange}
                              />
                            }
                            label="Parking"
                            labelPlacement="start"
                            name="parking"
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="event-space-add-valet"
                                name="valet"
                                checked={values.valet}
                                value={values.valet}
                                onChange={handleChange}
                              />
                            }
                            label="Valet"
                            labelPlacement="start"
                            name="valet"
                          />
                        </FormControl>
                      </div>

                      <div>
                        <FormControl sx={{ ml: -1 }}>
                          <FormControlLabel
                            control={
                              <Switch
                                id="event-space-add-isActive"
                                name="isActive"
                                checked={values.isActive}
                                value={values.isActive}
                                onChange={handleChange}
                              />
                            }
                            label="Active"
                            labelPlacement="start"
                            name="isActive"
                          />
                        </FormControl>
                      </div>
                    </Stack>

                    <div>
                      <FormControl sx={{ ml: -1 }}>
                        <FormControlLabel
                          label=""
                          labelPlacement="start"
                          control={
                            <ToggleButtonGroup
                              id="event-space-add-locationType"
                              value={values.locationType}
                              exclusive
                              onChange={(
                                event: React.MouseEvent<HTMLElement>,
                                locationType: EventSpaceType | null
                              ) => setFieldValue("locationType", locationType)}
                            >
                              <ToggleButton value={EventSpaceType.INDOOR}>
                                Indoor
                              </ToggleButton>
                              <ToggleButton value={EventSpaceType.BOTH}>
                                Both
                              </ToggleButton>
                              <ToggleButton value={EventSpaceType.OUTDOOR}>
                                Outdoor
                              </ToggleButton>
                            </ToggleButtonGroup>
                          }
                        />
                      </FormControl>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <Grid container direction="row-reverse">
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              <Loading isLoading={isSubmitting} />
            </Form>
          )}
        </Formik>
      </Box>
      {apiResponse.status === "failure" ? (
        <FailureResponse error={apiResponse.error} />
      ) : null}
    </>
  );
};
