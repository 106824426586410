import { URLS} from "../Constants";
import axios from "axios";
import { ICity, IPaginatedResponse } from "@skuare/common";
import {GetApiUrl} from "../utils";
import {User} from "firebase/auth";

export const GetGateKeepers = async (queryParams: { [key: string]: string| number } = {}, user: User | null) => axios.get<IPaginatedResponse<ICity>>(
    GetApiUrl(URLS.GATE_KEEPER_LIST, undefined, queryParams),
    user ? { headers: { Authorization: `Bearer ${await user.getIdToken(true)}` } }: {}
).then((resp) => resp.data);

export const GetGateKeeper = (gateKeeperId: string) => axios.get<ICity>(GetApiUrl(URLS.GATE_KEEPER_DETAIL, {id: gateKeeperId})).then((resp) => resp.data);
