import { useEffect, useState } from "react";
import { IPaginatedResponse } from "@skuare/common";
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridToolbar,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { Loading } from "../common";
import { getCurrentUser } from "../../services";
import { User } from "firebase/auth";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../Constants";
import { Button, Grid, Stack } from "@mui/material";

interface DataContainerProps {
  header: string;
  GetData: (
    queryParams: {
      [key: string]: string | number;
    },
    currentUser: User | null
  ) => Promise<IPaginatedResponse<any>>;
  columns: GridColDef[];
  path: PATHS;
  columnVisibilityModel?: { [key: string]: boolean };
  addNew?: string;
}

export function DataContainer<T>({
  header,
  GetData,
  columns,
  path,
  columnVisibilityModel = { id: false, createdAt: false },
  addNew,
}: DataContainerProps) {
  const { page = "1" } = useParams();
  const navigate = useNavigate();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [pageData, setPageData] = useState<IPaginatedResponse<T>>();
  const [paginationModel, setPaginationModel] = useState({
    page: parseInt(page) - 1,
    pageSize: 10,
  });
  const [rowCountState, setRowCountState] = useState(10);
  const [rows, setRows] = useState<T[]>(new Array<T>());
  const [queryFilter, setQueryFilter] = useState<T>();

  useEffect(() => {
    const getData = async () => {
      setIsDataLoading(true);
      const data = await GetData(
        {
          skip: paginationModel.pageSize * paginationModel.page,
          limit: paginationModel.pageSize,
        },
        getCurrentUser()
      );
      setPageData(data);
      setRows(data.results);
      setIsPageLoading(false);
      setIsDataLoading(false);
      setRowCountState(data.count);
      // navigate(GetPathUrl(path, {page: paginationModel.page + 1}))
    };
    getData();
  }, [paginationModel.page, paginationModel.pageSize, rowCountState]);

  const gridFilterToQueryParam = (filterModel: GridFilterModel) => {
    const appliedFilter = filterModel.items[0];
  };

  const onFilterChange = (filterModel: GridFilterModel) => {
    console.log(filterModel);
    gridFilterToQueryParam(filterModel);
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <h1>{header}</h1>
        {addNew ? (
          <Link to={addNew}>
            <Button variant="contained">Add {header}</Button>
          </Link>
        ) : null}
      </Stack>
      <Grid container alignContent="space-evenly" justifyContent="space-evenly">
        <Grid item></Grid>
        <Grid item></Grid>
      </Grid>
      {isPageLoading ? (
        <Loading isLoading={isPageLoading} />
      ) : (
        <div>
          <DataGrid
            initialState={{
              columns: {
                columnVisibilityModel,
              },
            }}
            rows={rows}
            rowCount={rowCountState}
            columns={columns}
            loading={isDataLoading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 50, 100]}
            checkboxSelection
            paginationMode="server"
            slots={{
              toolbar: GridToolbar,
            }}
            filterMode="server"
            onFilterModelChange={onFilterChange}
          />
        </div>
      )}
    </Box>
  );
}
