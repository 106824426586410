import axios from "axios";
import {IEstablishment, IPaginatedResponse} from "@skuare/common";
import {URLS} from "../Constants";
import {GetApiUrl} from "../utils";
import {User} from "firebase/auth";

export const GetOrders = async (queryParams: { [key: string]: string | number } = {}, user: User | null) => axios.get<IPaginatedResponse<IEstablishment>>(
    GetApiUrl(URLS.ORDER_LIST, undefined, queryParams),
    user ? { headers: { Authorization: `Bearer ${await user.getIdToken(true)}` } }: {}
).then((resp) => resp.data);

export const GetOrder = async (orderId: string, user: User | null) =>
    axios.get<IEstablishment>(
        GetApiUrl(URLS.ORDER_DETAIL, {id: orderId}),
        user ? { headers: { Authorization: `Bearer ${await user.getIdToken(true)}` } }: {}
    ).then((resp) => resp.data);
