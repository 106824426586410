import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PATHS } from "./Constants";
import { Container, createTheme, ThemeProvider } from "@mui/material";
import {
  AddArea,
  AddCity,
  AreaList,
  CityList,
  Login,
  Navigation,
} from "./components";
import {
  AddEstablishment,
  EditEstablishment,
  EstablishmentList,
} from "./components/Establishment";
import { AuthProvider } from "./hooks/Auth";
import { AddArtist, ArtistList, EditArtist } from "./components/Artist";
import { AddEventSpace, EventSpaceList } from "./components/EventSpace";
import { AddOffer, OfferList } from "./components/Offer";
import { OrderList } from "./components/Order";
import { GateKeeperList } from "./components/GateKeeper";
import {
  AddEventManager,
  EditEventManager,
  EventManagerList,
} from "./components/EventManager";
import { AddEvent, EditEvent, EventList } from "./components/Event";
import { AddCuisine, CuisineList } from "./components/Cuisine";
import { AddCategory, CategoryList } from "./components/Category";
import { MessageList } from "./components/Message";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <Router>
        <ThemeProvider theme={darkTheme}>
          <Navigation />
          <Container component="main" sx={{ flexGrow: 1, p: 10 }} maxWidth="xl">
            <Routes>
              <Route path={PATHS.LOGIN} element={<Login />} />
              <Route path={PATHS.AREA_ADD} element={<AddArea />} />
              <Route path={PATHS.AREA_LIST} element={<AreaList />} />
              <Route path={PATHS.ARTIST_ADD} element={<AddArtist />} />
              <Route path={PATHS.ARTIST_LIST} element={<ArtistList />} />
              <Route path={PATHS.ARTIST_EDIT} element={<EditArtist />} />
              <Route
                path={PATHS.ESTABLISHMENT_ADD}
                element={<AddEstablishment />}
              />
              <Route
                path={PATHS.ESTABLISHMENT_EDIT}
                element={<EditEstablishment />}
              />
              <Route
                path={PATHS.ESTABLISHMENT_LIST}
                element={<EstablishmentList />}
              />
              <Route
                path={PATHS.EVENT_MANAGER_ADD}
                element={<AddEventManager />}
              />
              <Route
                path={PATHS.EVENT_MANAGER_LIST}
                element={<EventManagerList />}
              />
              <Route
                path={PATHS.EVENT_MANAGER_EDIT}
                element={<EditEventManager />}
              />
              <Route path={PATHS.EVENTSPACE_ADD} element={<AddEventSpace />} />
              <Route
                path={PATHS.EVENTSPACE_LIST}
                element={<EventSpaceList />}
              />
              <Route path={PATHS.EVENT_ADD} element={<AddEvent />} />
              <Route path={PATHS.EVENT_EDIT} element={<EditEvent />} />
              <Route path={PATHS.EVENT_LIST} element={<EventList />} />
              <Route path={PATHS.CATEGORY_ADD} element={<AddCategory />} />
              <Route path={PATHS.CATEGORY_LIST} element={<CategoryList />} />
              <Route path={PATHS.CITY_ADD} element={<AddCity />} />
              <Route path={PATHS.CITY_LIST} element={<CityList />} />
              <Route path={PATHS.CUISINE_ADD} element={<AddCuisine />} />
              <Route path={PATHS.CUISINE_LIST} element={<CuisineList />} />
              <Route
                path={PATHS.GATE_KEEPER_LIST}
                element={<GateKeeperList />}
              />
              <Route path={PATHS.MESSAGE_LIST} element={<MessageList />} />
              <Route path={PATHS.OFFER_ADD} element={<AddOffer />} />
              <Route path={PATHS.OFFER_LIST} element={<OfferList />} />
              <Route path={PATHS.ORDER_LIST} element={<OrderList />} />
            </Routes>
          </Container>
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
