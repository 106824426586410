import React, { FC } from "react";
import { Button, ButtonGroup, Divider, Grid } from "@mui/material";
import { TicketForm } from "./TicketForm";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ITicketItem } from "@skuare/common";
import { v4 } from "uuid";
import { Formik, Form } from "formik";

interface AddEventStep5Errors {
  tickets: string;
}

interface AddEventStep5Props {
  value: any;
  onChange: (event: any, value: any, name: string) => void;
  setInValidStep: (value: (prevState: Set<number>) => Set<number>) => void;
}

export const AddEventStep5: FC<AddEventStep5Props> = ({
  value,
  onChange,
  setInValidStep,
}) => {
  const addTicket = async (
    id: string,
    setFieldValue: (name: string, value: any) => void
  ) => {
    const tickets = [
      ...value.tickets,
      {
        ticket: {
          id: id,
          ticketName: "",
          ticketDescription: "",
          entryFor: {
            male: false,
            female: false,
            other: false,
            couple: false,
            family: false,
          },
          price: 0,
          lastBookingTime: value.startDateTime,
          validFrom: value.startDateTime,
          validTo: value.endDateTime || value.startDateTime,
        },
        totalQuantity: 0,
        availableQuantity: 0,
        inTransaction: 0,
        bufferQuantity: 0,
      },
    ];
    setInValidStep((prevState: Set<number>) => {
      return new Set([...prevState, 4]);
    });
    onChange(undefined, tickets, "tickets");
    await setFieldValue("tickets", tickets);
  };

  const removeTicket = async (
    id: string,
    setFieldValue: (name: string, value: any) => void
  ) => {
    onChange(
      undefined,
      value.tickets.filter(
        (ticketItem: ITicketItem) => ticketItem.ticket.id !== id
      ),
      "tickets"
    );
    await setFieldValue(
      "tickets",
      value.tickets.filter(
        (ticketItem: ITicketItem) => ticketItem.ticket.id !== id
      )
    );
  };

  const onTicketValueChange = async (
    newTicketValue: ITicketItem,
    id: string,
    setFieldValue: (name: string, value: any) => void
  ) => {
    onChange(
      undefined,
      value.tickets.map((oldTicketValue: ITicketItem) =>
        oldTicketValue.ticket.id === id ? newTicketValue : oldTicketValue
      ),
      "tickets"
    );
    await setFieldValue(
      "tickets",
      value.tickets.map((oldTicketValue: ITicketItem) =>
        oldTicketValue.ticket.id === id ? newTicketValue : oldTicketValue
      )
    );
  };

  const getTicketForm = (
    ticketItem: ITicketItem,
    setFieldValue: (name: string, value: any) => void,
    displayAddButton = false
  ) => (
    <React.Fragment key={ticketItem.ticket.id}>
      <TicketForm
        value={ticketItem}
        onChange={(newTicketItem) =>
          onTicketValueChange(
            newTicketItem,
            ticketItem.ticket.id,
            setFieldValue
          )
        }
        setInValidStep={setInValidStep}
      />
      <br />
      <Divider light textAlign="center">
        <ButtonGroup variant="outlined">
          {displayAddButton ? (
            <Button
              startIcon={<KeyboardArrowDownIcon />}
              onClick={async () => await addTicket(v4(), setFieldValue)}
            >
              Add
            </Button>
          ) : null}
          <Button
            endIcon={<KeyboardArrowUpIcon />}
            onClick={async () =>
              await removeTicket(ticketItem.ticket.id, setFieldValue)
            }
          >
            Remove
          </Button>
        </ButtonGroup>
      </Divider>
      <br />
    </React.Fragment>
  );

  return (
    <Formik
      initialValues={value}
      onSubmit={() => {}}
      validate={() => {
        const errors: Partial<AddEventStep5Errors> = {};

        if (Object.keys(errors).length > 0) {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState, 4]);
          });
        } else {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState].filter((v) => v !== 4));
          });
        }

        return errors;
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item md={8}>
              <h2>Event Tickets</h2>
              {value.tickets.length ? (
                value.tickets.map((ticketItem: ITicketItem, index: number) =>
                  getTicketForm(
                    ticketItem,
                    setFieldValue,
                    index === value.tickets.length - 1
                  )
                )
              ) : (
                <Divider light textAlign="center">
                  <ButtonGroup variant="outlined">
                    <Button
                      startIcon={<KeyboardArrowDownIcon />}
                      onClick={async () => await addTicket(v4(), setFieldValue)}
                    >
                      Add
                    </Button>
                  </ButtonGroup>
                </Divider>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
