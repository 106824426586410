import React, { FC } from "react";
import { Box, Grid } from "@mui/material";
import { FileUploadField } from "../common";
import { Formik, Form } from "formik";

interface EditEventStep4Error {
  multimedia: string;
}

interface EditEventStep4Props {
  value: any;
  onChange: (event: any, value: any, name: string) => void;
  setInValidStep: (value: (prevState: Set<number>) => Set<number>) => void;
}
export const EditEventStep4: FC<EditEventStep4Props> = ({
  onChange,
  value,
  setInValidStep,
}) => {
  return (
    <Formik
      initialValues={value}
      onSubmit={() => {}}
      validate={(rawValues) => {
        const errors: Partial<EditEventStep4Error> = {};
        if (rawValues.multimedia.length === 0) {
          errors.multimedia = "At least one multimedia is required";
        }

        if (Object.keys(errors).length > 0) {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState, 3]);
          });
        } else {
          setInValidStep((prevState: Set<number>) => {
            return new Set([...prevState].filter((v) => v !== 3));
          });
        }

        return errors;
      }}
    >
      {({ errors, touched, setFieldValue, setFieldTouched }) => (
        <Form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item md={8}>
              <h2>Event Multi Media</h2>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "100%" },
                }}
              >
                <div>
                  <FileUploadField
                    id="add-event-multimedia"
                    name="Multi Media"
                    multi={true}
                    onChange={async (value) => {
                      setFieldTouched("multimedia");
                      await setFieldValue("multimedia", value);
                      onChange(undefined, value, "multimedia");
                    }}
                    value={value.multimedia}
                    error={touched.multimedia && !!errors.multimedia}
                  />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
