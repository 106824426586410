import {
  HostnameSocialMap,
  platformIconUrl,
  SocialPlatform,
  SocialPlatformDisplay,
} from "../Constants";
import { ISocialLink } from "@skuare/common";

export const linkToSocialLink = (link: string): ISocialLink => {
  const url = new URL(link);
  let platform = SocialPlatform.NA;

  if (url.hostname in HostnameSocialMap) {
    platform = HostnameSocialMap[url.hostname];
  }

  return {
    link: link,
    platform: SocialPlatformDisplay[platform],
    platformIconUrl: platformIconUrl(platform),
  };
};
