import { Autocomplete, TextField } from "@mui/material";
import { SocialLinkChip } from "./SocialLinkChip";
import React, { FC, useState } from "react";
import { ISocialLink } from "@skuare/common";
import { linkToSocialLink } from "../../utils";

interface SocialLinksFieldProps {
  value: ISocialLink[];
  id: string;
  onChange: (event: any, value: any, name: string) => void;
}

export const SocialLinksField: FC<SocialLinksFieldProps> = ({
  id,
  value,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] =
    useState<(string | ISocialLink)[]>(value);
  return (
    <Autocomplete
      style={{ width: "100%" }}
      disablePortal
      multiple
      id={id}
      options={value}
      sx={{ width: 300 }}
      freeSolo={true}
      value={selectedOptions}
      onChange={(event, options, reason) => {
        if (
          reason === "createOption" &&
          typeof options.slice(-1)[0] === "string"
        ) {
          let [newOption, ...oldOptions] = options.reverse();
          const newSocialLink = linkToSocialLink(newOption as string);
          setSelectedOptions([...oldOptions, newSocialLink]);
          onChange(event, [...oldOptions, newSocialLink], "socialLinks");
        } else {
          setSelectedOptions(options);
          onChange(event, options, "socialLinks");
        }
      }}
      renderTags={(value, getTagProps, ownerState) =>
        value.map((option, index) => (
          <SocialLinkChip
            socialLink={option as ISocialLink}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} name="socialLinks" label="Social Links" />
      )}
      isOptionEqualToValue={(option, value) => option.link === value.link}
      getOptionLabel={(option) => (option as ISocialLink).link}
    />
  );
};
