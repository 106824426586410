import { IMessage } from "@skuare/common";
import { GridColDef } from "@mui/x-data-grid";
import { DataContainer } from "../common";
import { PATHS } from "../../Constants";
import { GetMessages } from "../../api";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    flex: 1,
  },
  {
    field: "subject",
    headerName: "Subject",
    flex: 1,
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
];

const columnVisibilityModel = {
  id: false,
};

export const MessageList = () => (
  <DataContainer<IMessage>
    header="Message"
    GetData={GetMessages}
    columns={columns}
    path={PATHS.MESSAGE_LIST}
    columnVisibilityModel={columnVisibilityModel}
  />
);
