import React from "react";
import { Box } from "@mui/material";

interface StackRowProps<ItemRenderProps> {
  items: ItemRenderProps[];
  ItemRender: React.ElementType;
}

export function StackRow<ItemRenderProps>({
  items,
  ItemRender,
}: StackRowProps<ItemRenderProps>) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {items.map((item) => (
        <Box
          sx={(theme) => ({
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
          })}
        >
          <ItemRender {...item} />
        </Box>
      ))}
    </Box>
  );
}
