import { IEventSpace } from "@skuare/common";
import { GridColDef } from "@mui/x-data-grid";
import { DataContainer } from "../common";
import { PATHS } from "../../Constants";
import { Chip } from "@mui/material";
import { GetEventSpaces } from "../../api";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    flex: 1,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "address",
    headerName: "Address",
    flex: 2,
  },
  {
    field: "area",
    headerName: "Area",
    flex: 1,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      v1.name < v2.name ? -1 : 1,
    valueFormatter: (params) => `${params.value.name}`,
  },
  {
    field: "locationType",
    headerName: "Location Type",
    flex: 0.75,
    renderCell: (params) => <Chip label={params.value} />,
  },
  {
    field: "isActive",
    headerName: "Active",
    type: "boolean",
    flex: 0.5,
    editable: true,
  },
  {
    field: "createdAt",
    headerName: "Created",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
  {
    field: "updatedAt",
    headerName: "Updated",
    type: "dateTime",
    flex: 1,
    valueFormatter: (params) => new Date(params.value).toLocaleString(),
  },
];

const columnVisibilityModel = {
  id: false,
  createdAt: false,
};

export const EventSpaceList = () => (
  <DataContainer<IEventSpace>
    header="Event Space"
    GetData={GetEventSpaces}
    columns={columns}
    path={PATHS.EVENTSPACE_LIST}
    columnVisibilityModel={columnVisibilityModel}
    addNew={PATHS.EVENTSPACE_ADD}
  />
);
