import { URLS } from "../Constants";
import axios from "axios";
import { ICity, IPaginatedResponse } from "@skuare/common";
import { GetApiUrl } from "../utils";
import { User } from "firebase/auth";

export const GetCities = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<ICity>>(
      GetApiUrl(URLS.CITY_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const GetCity = (cityId: string) =>
  axios
    .get<ICity>(GetApiUrl(URLS.CITY_DETAIL, { id: cityId }))
    .then((resp) => resp.data);

export const CreateCity = async (city: Partial<ICity>, user: User | null) => {
  try {
    const { data } = await axios.post<Partial<ICity>>(
      GetApiUrl(URLS.CITY_ADD),
      city,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
