import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AvatarGroup,
  Chip,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  Skeleton,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  EventByType,
  IAggregatedEvent,
  IArea,
  IArtist,
  ICategory,
  ICuisine,
  IEstablishment,
  IEventManager,
  IOrganizer,
  MultiMediaType,
  ShowDateRange,
} from "@skuare/common";

import LocalParkingIcon from "@mui/icons-material/LocalParking";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import CarRentalIcon from "@mui/icons-material/CarRental";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageIcon from "@mui/icons-material/Image";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PlaceIcon from "@mui/icons-material/Place";
import EditIcon from "@mui/icons-material/Edit";
import Plot from "react-plotly.js";

import { GetEvent, GetEventStats, PatchEvent } from "../../api";
import { getCurrentUser } from "../../services";

import { PATHS, Rupee } from "../../Constants";

import { EventTypeChip } from "./EventTypeChip";
import { ArtistChip, ArtistChipProps } from "../Artist";
import {
  LiquorIconChip,
  OrganizerChip,
  OrganizerChipProps,
  ParkingIconChip,
  SnackBar,
  SocialLinkChip,
  SocialLinkChipProps,
  StackRow,
  ValetIconChip,
} from "../common";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShareIcon from "@mui/icons-material/Share";
import Button from "@mui/material/Button";
import { ChipProps } from "@mui/material/Chip/Chip";
import { GetPathUrl } from "../../utils";

interface EventStatCardProps {
  eventId: string;
  setEvents: (value: any) => void;
}
export const EventStatCard: FC<EventStatCardProps> = ({
  eventId,
  setEvents,
}) => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [event, setEvent] = useState<IAggregatedEvent>();
  const [isActive, setIsActive] = useState<boolean>(true);
  const [eventStats, setEventStats] = useState({
    shareStats: {},
    actionStats: [],
    totalView: 0,
  });

  const [expanded, setExpanded] = useState<string | false>("details");

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const getData = async () => {
      const data = await GetEvent(eventId, getCurrentUser());
      setEvent(data);
      setIsActive(data.isActive);
      const statsData = await GetEventStats(eventId, getCurrentUser());
      setEventStats(statsData);
      setIsPageLoading(false);
    };

    getData();
  }, [eventId]);

  const handleActiveStatus = async (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e, e.target.checked);
    setIsActive(e.target.checked);
    await PatchEvent(
      eventId,
      { isActive: Boolean(e.target.checked) },
      await getCurrentUser()
    );
    setEvents((prevState: any) =>
      prevState.map((_: any) =>
        _.id === eventId ? { ..._, isActive: e.target.checked } : _
      )
    );
  };

  return isPageLoading || event === undefined ? (
    <Skeleton variant="rectangular" width="100%" height="100%" />
  ) : (
    <>
      <Grid container>
        <Grid item md={8}>
          <Stack>
            <Typography component="div" variant="h5">
              {`${event.title} by ${event.venue.name}, ${
                (event.area as IArea).name
              }`}
            </Typography>
            <Typography variant="h6" color="text.secondary" component="div">
              {ShowDateRange(event.startDateTime, event.endDateTime)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item md={1}>
          <Link to={GetPathUrl(PATHS.EVENT_EDIT, { id: eventId })}>
            <IconButton color="info">
              <EditIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item md={1}>
          <Link
            to={event?.shareUrl || ""}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            <IconButton color="info">
              <VisibilityIcon />
            </IconButton>
          </Link>
        </Grid>
        <Grid item md={1}>
          <IconButton color="info">
            <ShareIcon
              onClick={() => {
                setMessage("Share URL copied to clipboard");
                setOpen(true);
                navigator.clipboard.writeText(event?.shareUrl || "");
              }}
            />
          </IconButton>
        </Grid>
        <Grid item md={1}>
          <Switch
            key={`event-stat-card-${eventId}`}
            checked={isActive}
            value={isActive}
            onChange={handleActiveStatus}
          />
        </Grid>
      </Grid>
      <Accordion
        expanded={expanded === "details"}
        onChange={handleChange("details")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Details</Typography>
          <Grid
            container
            justifyContent="space-evenly"
            style={{ animationDelay: "revert" }}
          >
            <Grid item>
              <EventTypeChip type={event.type} />
            </Grid>
            <Grid item>
              <AvatarGroup max={5}>
                {event.parking ? <LocalParkingIcon /> : null}
                {event.valet ? <CarRentalIcon /> : null}
                {event.liquor ? <LocalBarIcon /> : null}
              </AvatarGroup>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "text.secondary" }}>
                {`${event.artists.length} Artist(s)`}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableRow>
              <TableCell variant="head">Event Type</TableCell>
              <TableCell>{event.typeDisplay}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Organizers</TableCell>
              <TableCell>
                <StackRow<OrganizerChipProps>
                  items={[
                    {
                      organizer: {
                        by:
                          event.eventByType === EventByType.Establishment
                            ? (event.eventBy as IEstablishment)
                            : (event.eventBy as IEventManager),
                        type: event.eventByType,
                      },
                    },
                    ...event.organizers.map((organizer: IOrganizer) => ({
                      organizer,
                    })),
                  ]}
                  ItemRender={OrganizerChip}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Venue</TableCell>
              <TableCell>
                {`${event.venue.name} @ ${event.venue.address}`}
                {event.gmapUrl ? (
                  <Link
                    to={event.gmapUrl}
                    target="_blank"
                    referrerPolicy="no-referrer"
                  >
                    <IconButton>
                      <PlaceIcon />
                    </IconButton>
                  </Link>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Artists</TableCell>
              <TableCell>
                <StackRow<ArtistChipProps>
                  items={event.artists.map((artist) => ({
                    artist: artist as IArtist,
                  }))}
                  ItemRender={ArtistChip}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Social Links</TableCell>
              <TableCell>
                <StackRow<SocialLinkChipProps>
                  items={event.socialLinks.map((socialLink) => ({
                    socialLink,
                  }))}
                  ItemRender={SocialLinkChip}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Cuisines</TableCell>
              <TableCell>
                <StackRow<ChipProps>
                  items={event.cuisines.map((cuisine) => ({
                    label: (cuisine as ICuisine).name,
                  }))}
                  ItemRender={Chip}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Categories</TableCell>
              <TableCell>
                <StackRow<ChipProps>
                  items={event.categories.map((category) => ({
                    label: (category as ICategory).name,
                  }))}
                  ItemRender={Chip}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Amenities</TableCell>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  {event.parking ? <ParkingIconChip /> : null}
                  {event.valet ? <ValetIconChip /> : null}
                  {event.liquor ? <LiquorIconChip /> : null}
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Id</TableCell>
              <TableCell>
                <Button
                  endIcon={<ContentCopyIcon />}
                  onClick={() => {
                    setMessage("ID Copied to clipboard");
                    setOpen(true);
                    navigator.clipboard.writeText(event.id);
                  }}
                  variant="outlined"
                  color="info"
                >
                  {event.id}
                </Button>
              </TableCell>
            </TableRow>
          </Table>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "description"}
        onChange={handleChange("description")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Description
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{event.description}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "media"}
        onChange={handleChange("media")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Media</Typography>
          <Grid container justifyContent="space-evenly">
            <Grid item>
              <Chip
                icon={<ImageIcon />}
                label={`${
                  event.multimedia.filter(
                    (m) => m.type === MultiMediaType.Image
                  ).length
                } Image(s)`}
              />
            </Grid>
            <Grid item>
              <Chip
                icon={<OndemandVideoIcon />}
                label={`${
                  event.multimedia.filter(
                    (m) => m.type === MultiMediaType.Video
                  ).length
                } Video(s)`}
              />
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <ImageList sx={{ width: "100%" }} cols={3}>
            {event.multimedia.map((item) => (
              <ImageListItem key={item.url}>
                <img src={item.url} alt={event.title} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "tickets"}
        onChange={handleChange("tickets")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: "33%", flexShrink: 0 }}>Tickets</Typography>
          <Grid container justifyContent="space-evenly">
            {event.tickets.map((ticket) => (
              <Grid item>
                <Chip
                  label={`${ticket.ticket.ticketName} (${
                    ticket.ticket.price
                      ? `${Rupee}${ticket.ticket.price}`
                      : "RSVP"
                  })`}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableCell>Ticket Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Available</TableCell>
              <TableCell>Sold</TableCell>
              <TableCell>In Transaction</TableCell>
            </TableHead>
            <TableBody>
              {event.tickets.map((ticket) => (
                <TableRow>
                  <TableCell>{ticket.ticket.ticketName}</TableCell>
                  <TableCell>{ticket.ticket.price}</TableCell>
                  <TableCell>{ticket.totalQuantity}</TableCell>
                  <TableCell>{ticket.availableQuantity}</TableCell>
                  <TableCell>{ticket.sold}</TableCell>
                  <TableCell>{ticket.inTransaction}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
      {eventStats ? (
        <Accordion
          expanded={expanded === "stats"}
          onChange={handleChange("stats")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Stats</Typography>
            <Grid
              container
              justifyContent="space-evenly"
              style={{ animationDelay: "revert" }}
            >
              <Grid item>
                <Typography sx={{ color: "text.secondary" }}>
                  {`${eventStats.totalView.toLocaleString()} Views`}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item md={12}>
                <Plot
                  data={[
                    {
                      x: eventStats.actionStats.map((_: any) =>
                        new Date(_.date).toDateString()
                      ),
                      y: eventStats.actionStats.map((_: any) => _.hour),
                      text: eventStats.actionStats.map((_: any) => {
                        const date = new Date(_.date);
                        date.setHours(_.hour);
                        return `${date.toDateString()} ${_.hour} to ${
                          (_.hour + 1) % 24
                        } hours: ${_.view} Views`;
                      }),
                      mode: "markers",
                      marker: {
                        size: eventStats.actionStats.map((_: any) => {
                          if (_.view < 10) return 3;
                          if (_.view < 100) return 5;
                          if (_.view < 1000) return 9;
                          if (_.view < 10000) return 12;
                          return 20;
                        }),
                      },
                    },
                  ]}
                  layout={{
                    title: "Event Views",
                    height: 600,
                    width: 900,
                    xaxis: {
                      title: "Date",
                    },
                    yaxis: {
                      title: "Hours",
                      autorange: "reversed",
                      range: [0, 24],
                      tickvals: [0, 4, 8, 12, 16, 20, 24],
                    },
                    paper_bgcolor: "#212529",
                    plot_bgcolor: "#212529",
                    font: {
                      color: "#FFFFFF",
                    },
                  }}
                  useResizeHandler={true}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <SnackBar
        open={open}
        handleClose={() => setOpen(false)}
        message={message}
      />
    </>
  );
};
