import axios from "axios";
import { IEstablishment, IPaginatedResponse } from "@skuare/common";
import { URLS } from "../Constants";
import { GetApiUrl } from "../utils";
import { User } from "firebase/auth";

export const GetEstablishments = async (
  queryParams: { [key: string]: string | number } = {},
  user: User | null
) =>
  axios
    .get<IPaginatedResponse<IEstablishment>>(
      GetApiUrl(URLS.ESTABLISHMENT_LIST, undefined, queryParams),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const GetEstablishment = async (
  establishmentId: string,
  user: User | null
) =>
  axios
    .get<IEstablishment>(
      GetApiUrl(URLS.ESTABLISHMENT_DETAIL, { id: establishmentId }),
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    )
    .then((resp) => resp.data);

export const CreateEstablishment = async (
  establishment: Partial<IEstablishment>,
  user: User | null
) => {
  try {
    const { data } = await axios.post<Partial<IEstablishment>>(
      GetApiUrl(URLS.ESTABLISHMENT_ADD),
      establishment,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};

export const UpdateEstablishment = async (
  id: string,
  establishment: Partial<IEstablishment>,
  user: User | null
) => {
  try {
    const { data } = await axios.put<Partial<IEstablishment>>(
      GetApiUrl(URLS.ESTABLISHMENT_DETAIL, { id }),
      establishment,
      user
        ? {
            headers: { Authorization: `Bearer ${await user.getIdToken(true)}` },
          }
        : {}
    );
    return data;
  } catch (e: any) {
    throw e.response;
  }
};
