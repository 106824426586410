import { GetEventmanagers } from "../../api";
import { IEventManager } from "@skuare/common";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { DataContainer } from "../common";
import { PATHS } from "../../Constants";
import EditIcon from "@mui/icons-material/Edit";
import { GetPathUrl } from "../../utils";
import { useNavigate } from "react-router-dom";

export const EventManagerList = () => {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "nameOfCompany",
      headerName: "Company",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "isActive",
      headerName: "Active",
      type: "boolean",
      flex: 1,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created",
      type: "dateTime",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      type: "dateTime",
      flex: 1,
      valueFormatter: (params) => new Date(params.value).toLocaleString(),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() =>
              navigate(GetPathUrl(PATHS.EVENT_MANAGER_EDIT, { id }))
            }
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <DataContainer<IEventManager>
      header="Event Manager"
      GetData={GetEventmanagers}
      columns={columns}
      path={PATHS.EVENT_MANAGER_LIST}
      addNew={PATHS.EVENT_MANAGER_ADD}
    />
  );
};
